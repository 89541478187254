import React from "react";
import { Button, Grid } from "@material-ui/core";
import {
  makeStyles,
  Typography,
  TextField,
  CardMedia,
  Modal,
} from "@material-ui/core";
import image from "../../images/limes_accedi_bg.jpg";

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
    height: "auto",
  },

  mapImage: {
    height: "100%",
  },

  input: {
    backgroundColor: "#fff",
    "& div": {
      borderRadius: 0,
    },
  },
  button: {
    backgroundColor: "#00416b",
    color: "#ffff",
    borderRadius: "0",
    padding: "10px 84px",
    "&:hover": {
      backgroundColor: "#00416b",
      color: "#ffff",
    },
  },
  wrap: {
    marginTop: 250,
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    left: "calc(50% - 200px)",
    top: "calc(50% - 100px)",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: "16px",
    outline: "none",
    "& p": {
      color: "red",
      textAlign: "center",
    },
  },
}));
const AccediSections = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const body = (
    <div className={classes.paper}>
      <p id="simple-modal-description">Credenziali errate</p>
    </div>
  );
  return (
    <div className="page-wrapper-acccedi">
      <Grid container>
        <Grid item md={6} className="image_warpper">
          <CardMedia
            title="cardMedia"
            image={image}
            className="map_image hide"
          />
        </Grid>
        <Grid item md={6}>
          <div className="login_section">
            <Typography variant="h1" color="initial" className="login-title">
              ACCEDI ALL’AREA RISERVATA
            </Typography>

            <div className="login-text">
              <Typography>Ciao benvenuto,</Typography>
              <Typography>
                inserisci le credenziali per accedere al tuo profilo
              </Typography>
            </div>
            <Grid container>
              <Grid item md={12} xs={12}>
                <div className="email">
                  <label>Nome utente o e-mail</label>
                  <TextField
                    fullWidth
                    name="name"
                    className={classes.input}
                    placeholder="Inserisci"
                    variant="outlined"
                    required={true}
                  />
                </div>
              </Grid>
              <Grid item md={12} xs={12}>
                <div className="password">
                  <label>Password</label>
                  <TextField
                    fullWidth
                    type="password"
                    name="password"
                    className={classes.input}
                    placeholder="Inserisci"
                    variant="outlined"
                    required={true}
                  />
                </div>
              </Grid>
            </Grid>

            <Button
              type="submit"
              className={classes.button}
              onClick={handleOpen}
              variant="contained"
            >
              Accedi
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>

            <span className="login_span">
              Effettuando accetti il nostri Contratti di licenza, la nostra
              Informativa sulla privacy (compreso l’Uso dei cookie, e di altre
              tecnologie) e le Condizioni d’uso.
            </span>
            {/*   <Typography>
              Hai dimenticato il <a>nome utente</a> o la <a>password?</a>
            </Typography> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AccediSections;
