import React from "react";
import {
  ListItem,
  Typography,
  makeStyles,
  Divider,
  Grid,
} from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  list: {
    backgroundColor: "#CCD8EE",
    marginTop: "2rem",
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      margin: 0,
      /* "&:nth-last-child(1)": {
        borderBottom: "1px solid",
      }, */
    },
  },
}));

const listItems = [
  {
    title: "Introduzione alla disciplina",
    text: "Che cos'è la geopolitica. Presupposti filosofici. Il fattore umano, ossia lo studio delle collettività, unità minima d’analisi. Le distinzioni fra strategia e tattica e fra potenze storiche e attori post-storici.",
    panel: "panel1",
  },
  {
    title: "Come si scrive e si disegna un’analisi geopolitica",
    text: " Il metodo per impostare un’analisi geopolitica. Rudimenti di cartografia geopolitica, per imparare a mappare, dunque a gerarchizzare, i fattori e le informazioni.",
    panel: "panel2",
  },
  {
    title: "Gli strumenti della potenza",
    text: "Lo Stato e gli Stati profondi. La storia e il suo uso. Pedagogie nazionali a confronto. Analisi dei codici interpretativi e negoziali. Le dimensioni della competizione: economia, finanza, intelligence, domini militari.",
    panel: "panel3",
  },
  {
    title: "Le crisi che definiscono il mondo ",
    text: "Dopo aver appreso gli strumenti della geopolitica, osserviamo la loro applicazione pratica nell’analisi dei principali conflitti, dalla tempesta interna agli Stati Uniti allo scontro sino-americano nel Pacifico, dalla Russia in guerra fino alla frammentazione delle Europe.",
    panel: "panel4",
  },
  {
    title: "Una strategia per l’Italia",
    text: "Le caratteristiche strutturali del nostro paese. L’impatto su di noi delle crisi analizzate. Come elaborare una strategia.",
    panel: "panel5",
  },
  {
    title: "Esercitazioni",
    text: "Come leggere in chiave geopolitica le notizie; imparare a scrivere brevi analisi; disegnare una carta geopolitica.",
    panel: "panel6",
  },
];

const Matrrie2 = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          I TEMI DEL CORSO
        </Typography>
        <Divider />
        <Grid container>
          <Grid item>
            <div className={classes.list}>
              {listItems.map((listitem, index) => (
                <Accordion
                  expanded={expanded === listitem.panel}
                  onChange={handleChange(listitem.panel)}
                  key={listitem.title}
                  className="accordion"
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className="accordion-title">
                      {listitem.title}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{listitem.text}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Matrrie2;
