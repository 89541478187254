import React from "react";
import Block from "./Block.jsx";

const VideoBlock = () => {
  return (
    <div className="section-wrapper">
      <Block />
    </div>
  );
};

export default VideoBlock;
