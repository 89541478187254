import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";
import { Link } from "react-router-dom";
import maincolors from "../Colors/Color.js";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    paddingTop: "1rem",

    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
}));

const FormazBlog = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          Formazione per le aziende
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Le chiavi di lettura puramente economico-finanziarie non sono più
              sufficienti a navigare la complessità internazionale. La Scuola di
              Limes fornisce consulenze su misura per le imprese che proiettano
              se stesse e il paese in un mondo sempre più competitivo.
            </Typography>
          </Grid>
        </Grid>
        <Link className={classes.link} to="/perleaziende">
          Scopri la formazione per le aziende…
        </Link>
      </div>
    </div>
  );
};

export default FormazBlog;
