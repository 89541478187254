import React from "react";
import Cost from "./Cost.jsx";
import Rectangle from "../Layout/Rectangle.jsx";
import { Typography, makeStyles, Grid, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
}));

const CoursePrice = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper" id="COME-FUNZIONA">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
        COME FUNZIONA 
        </Typography>
        <Divider />
        <Grid container spacing={2}>
          <Grid item md={12}>
            <Cost />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CoursePrice;
