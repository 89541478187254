import React from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";

import Rectangle from "../Layout/Rectangle.jsx";
import DocintiImages from "../../helpers/DocintiImages";
import Valerii from "../../images/docinti/Valerii-400.jpg";
import Trombetta from "../../images/docinti/Trombetta-400.jpg";
import Spagnulo from "../../images/docinti/Spagnulo-400.jpg";
import Smitson from "../../images/docinti/Smitson-400.jpg";
import Santoro from "../../images/docinti/Santoro-400.jpg";
import Roccucci from "../../images/docinti/Roccucci-400.jpg";
import Lukjanov from "../../images/docinti/Lukjanov-400.jpg";
import Suzuki from "../../images/docinti/Suzuki-400.jpg";
import Giro from "../../images/docinti/Giro-400.jpg";
import Fayet from "../../images/docinti/Fayet-400.jpg";
import Dimuro from "../../images/docinti/DiMuro-400.jpg";
import Caridi from "../../images/docinti/Caridi-400.jpg";
import Bianco from "../../images/docinti/Bianco-400.jpg";
import Benassi from "../../images/docinti/Benassi-400.jpg";
import Aresu from "../../images/docinti/Aresu-400.jpg";
import Zichen from "../../images/docinti/Zichen-400.jpg";
import Sisci from "../../images/docinti/sisci.jpg";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,

      marginBottom: 16,
      "& svg": {
        float: "right",
      },
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 400,
  },
  root: {
    boxShadow: "none",
    borderRadius: 0,
  },
  cardContent: {
    padding: "16px 0",
    paddingBottom: 60,
  },
  text: {
    paddingTop: "1rem",

    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 112,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
  container: {
    marginTop: "1rem",
  },
  description: {
    paddingTop: "1rem",
  },
}));

const TUTTI_I_DOCENTI = [
  {
    id: "5",
    firstname: "Rosario",
    lastname: "Aitala",
    auth: "Magistrato",
    image: DocintiImages["aitala.jpg"].default,
    description:
      "Giudice della Corte Penale Internazionale. Ha lavorato da magistrato a Milano, Trapani e Roma e ha vissuto in Albania, Afghanistan e America Latina occupandosi di diplomazia, sicurezza, mafie e terrorismi.",
  },
  {
    id: "6",
    firstname: "Giuseppe",
    lastname: "Cucchi",
    auth: "Generale",
    image: DocintiImages["Cucchi.jpg"].default,
    description:
      "Generale, già direttore del Dipartimento delle informazioni per la sicurezza, dal 1991 al 1997 direttore del Centro militare di studi strategici, è stato consigliere militare della Presidenza del Consiglio dei ministri.",
  },
  {
    id: "10",
    firstname: "Paolo",
    lastname: "Peluffo",
    auth: "Storico e dirigente pubblico",
    image: DocintiImages["peluffo.jpg"].default,
    description:
      "Già segretario generale del Consiglio nazionale dell’economia e del lavoro. Storico del Risorgimento, è stato consigliere del Presidente della Repubblica Carlo Azeglio Ciampi.",
  },
  {
    id: "11",
    firstname: "Francesco",
    lastname: "Sisci",
    auth: "Sinologo",
    // image: DocintiImages["sisci.jpg"].default,
    image: Sisci,
    description:
      "Professore associato all’Università del popolo cinese. Segue da vicino i rapporti tra Pechino e Vaticano. È stato il primo a intervistare papa Francesco sulle relazioni con la Cina.",
  },
  {
    id: "12",
    firstname: "George",
    lastname: "Friedman (Usa)",
    auth: "Analista geopolitico",
    image: DocintiImages["friedmann.jpg"].default,
    description:
      "Tra i massimi esperti di geopolitica, è fondatore e amministratore delegato di Geopolitical Futures. Già fondatore di Stratfor, ha lavorato a lungo negli apparati statunitensi, specie nell’Office of Net Assessment.",
  },
  {
    id: "15",
    firstname: "You",
    lastname: "Ji (Cina)",
    auth: "Analista geopolitico",
    image: DocintiImages["youji.jpg"].default,
    description:
      "Capo del dipartimento di Governo e pubblica amministrazione all’Università di Macao. Studioso della geostrategia e degli affari militari della Repubblica Popolare di Cina.",
  },
  {
    id: "19",
    firstname: "Pierre-Emmanuel",
    lastname: "Thomann (Francia)",
    auth: "Analista geopolitico",
    image: DocintiImages["Thomann.jpg"].default,
    description:
      "Dottore in geopolitica, allievo dell’Institut français de géopolitique, presidente di Eurocontinent, fornisce consulenze alle istituzioni dell’Unione Europea.",
  },
  {
    id: "20",
    firstname: "Abdolrasool",
    lastname: "Divsallar (Iran)",
    auth: "Analista geopolitico",
    image: DocintiImages["divsalla.jpg"].default,
    description:
      "Senior Scholar al Middle East Institute di Washington, Senior Researcher allo United Nations Institute for Disarmament Research (Unidir). Ha lavorato presso il Centro di studi strategici di Teheran guidato all’epoca dall’ex presidente della Repubblica Islamica d’Iran, Hassan Rohani.",
  },
  {
    id: "21",
    firstname: "Franco",
    lastname: "Bernabè",
    auth: "Manager e imprenditore",
    image: DocintiImages["Bernabè.jpg"].default,
    description:
      "Presidente di Cellnex, il più importante operatore indipendente europeo di infrastrutture di telecomunicazioni mobili. È stato a.d. di Eni e Telecom Italia.",
  },
  {
    id: "22",
    firstname: "Alessandro",
    lastname: "Aresu",
    auth: "Analista geopolitico",
    image: Aresu,
    description:
      "Consigliere scientifico di Limes. Scrive per numerose riviste tra cui “L’Espresso”, “La Stampa” e “Le Grand Continent”. Dal 2021 è consigliere alla Presidenza del Consiglio dei Ministri.",
  },
  {
    id: "23",
    firstname: "Pietro",
    lastname: "Benassi",
    auth: "Diplomatico",
    image: Benassi,
    description:
      "Già sottosegretario di Stato alla Presidenza del Consiglio dei Ministri, Rappresentante permanente d’Italia per l’Unione europea e ambasciatore d’Italia in Tunisia e Germania.",
  },
  {
    id: "24",
    firstname: "Cinzia",
    lastname: "Bianco",
    auth: "Analista geopolitico",
    image: Bianco,
    description:
      "Ricercatrice e analista del Consiglio europeo per le relazioni estere, dove si occupa di penisola araba e delle relazioni con l’Europa. Ha svolto ricerche nel Golfo per il progetto “Shakara” della Commissione europea.",
  },
  {
    id: "25",
    firstname: "Paola",
    lastname: "Caridi",
    auth: "Analista geopolitico",
    image: Caridi,
    description:
      "Saggista, storica. Corrispondente prima dal Cairo e poi per dieci anni da Gerusalemme per “Lettera22”, di cui è presidente e cofondatrice. Autrice di “Hamas” (2023).",
  },
  {
    id: "26",
    firstname: "Lorenzo",
    lastname: "Di Muro",
    auth: "Analista geopolitico",
    image: Dimuro,
    description:
      "Consigliere redazionale di Limes. Si occupa di India-Cina-Usa, Indo-Pacifico e America Latina. Collabora con “Aspenia”.",
  },
  {
    id: "27",
    firstname: "Héloïse",
    lastname: "Fayet (Francia)",
    auth: "Analista geopolitico",
    image: Fayet,
    description:
      "Presso l’Institut français des relations internationales è research fellow nel Centro di studi di sicurezza e a capo del programma di ricerca di deterrenza e proliferazione. Ha lavorato come analista per le Forze armate francesi e per l’Agenzia nazionale francese di cybersicurezza.",
  },
  {
    id: "28",
    firstname: "Mario",
    lastname: "Giro",
    auth: "Analista geopolitico",
    image: Giro,
    description:
      "Già viceministro degli Esteri della Repubblica Italiana. Responsabile delle relazioni internazionali della comunità di Sant’Egidio. Tra le altre attività, ha contribuito a implementare l’accordo tra Serbia e Kosovo nel 1996 e ha partecipato a diverse missioni come mediatore nel Sudan del Sud nel 2006.",
  },
  {
    id: "29",
    firstname: "Suzuki",
    lastname: "Kazuto (Giappone)",
    auth: "Analista geopolitico",
    image: Suzuki,
    description:
      "Docente di “Politica della scienza e della tecnologia” alla Graduate School of Public Policy dell’Università di Tōkyō. Senior Fellow all’Asia Pacific Initiative (Api). Direttore dell’Istituto di geoeconomia presso la International House of Japan.",
  },
  {
    id: "30",
    firstname: "Fëdor",
    lastname: "Luk'janov (Russia)",
    auth: "Analista geopolitico",
    image: Lukjanov,
    description:
      "Direttore di “Russia in Global Affairs”. Direttore di ricerca del Club di Discussione Valdai. Docente alla facoltà di Economia Mondiale e Affari Internazionali all’Università Nazionale di Ricerca – Scuola superiore di Economia di Mosca.",
  },
  {
    id: "31",
    firstname: "Adriano",
    lastname: "Roccucci",
    auth: "Storico",
    image: Roccucci,
    description:
      "Professore ordinario di Storia contemporanea all’Università Roma Tre. Direttore del Centro interuniversitario di Ricerca sulla Russia Contemporanea e le sue Eredità Culturali. Socio e già vicedirettore della Società Italiana per lo Studio della Storia Contemporanea (SISSCo).",
  },
  {
    id: "32",
    firstname: "Daniele",
    lastname: "Santoro",
    auth: "Analista geopolitico",
    image: Santoro,
    description:
      "Coordinatore Turchia e mondo turco di Limes.",
  },
  {
    id: "33",
    firstname: "Scott",
    lastname: "Smitson (Usa)",
    auth: "Analista geopolitico",
    image: Smitson,
    description:
      "Direttore del programma di Grand Strategy alla Denison University e veterano dello U.S. Army. Ha lavorato in Europa, Nord Africa, Medio Oriente e Asia.",
  },
  {
    id: "34",
    firstname: "Marcello",
    lastname: "Spagnulo",
    auth: "Ingegnere",
    image: Spagnulo,
    description:
      "Consigliere scientifico di Limes. Ingegnere aeronautico e già presidente del Mars Center. Ha lavorato presso l’Agenzia spaziale europea e l’Agenzia spaziale italiana.",
  },
  {
    id: "35",
    firstname: "Lorenzo",
    lastname: "Trombetta",
    auth: "Analista geopolitico",
    image: Trombetta,
    description:
      "Corrispondente per Limes dal Medio Oriente. Autore di “Siria, dagli ottomani agli Asad. E oltre” (2014) e di “Negoziazione e potere in Medio Oriente” (2022). Ha svolto consulenze per agenzie Onu e altre organizzazioni internazionali che operano nella regione.",
  },
  {
    id: "36",
    firstname: "Massimiliano",
    lastname: "Valerii",
    auth: "Docente universitario",
    image: Valerii,
    description:
      "Direttore generale del Censis. Curatore dell’annuale “Rapporto sulla situazione sociale del Paese”. Docente di “Media, società, istituzioni” all’Università Sapienza di Roma.",
  },
  {
    id: "37",
    firstname: "Wang",
    lastname: "Zichen (Cina)",
    auth: "Analista geopolitico",
    image: Zichen,
    description:
      "Research Fellow al Center for China and Globalization (Ccg) di Pechino. Fondatore di “Pekingnology”.",
  },
];

const sorteddocents = TUTTI_I_DOCENTI.sort(function (a, b) {
  return a.lastname.localeCompare(b.lastname);
});

const TUTTI_I_DOCENTISection = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          Le testimonianze
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.description}>
              Analisti italiani e stranieri della rete di Limes, dirigenti e
              funzionari di Stato provenienti dalle strutture di governo,
              dall’intelligence, dalle Forze armate, protagonisti del mondo
              economico-finanziario.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          className={`flip-cards-container ${classes.container}`}
        >
          {sorteddocents.map((docent) => {

            return (
              <Grid
                item
                key={docent.firstname}
                md={3}
                sm={6}
                xs={12}
                className="flip-card flip-cards-4"
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      src={docent.image}
                      className={classes.image}
                      title="Image title"
                    />
                    <Typography variant="h5" className="authors-name">
                      {docent.firstname}
                    </Typography>
                    <Typography variant="h5" className="authors-name">
                      {docent.lastname}
                    </Typography>
                    <Typography gutterBottom className="card-title">
                      {docent.auth}
                    </Typography>
                  </div>
                  <div className={`flip-card-back ${classes.cardContent}`}>
                    <Typography className="card-text">
                      {docent.description}{" "}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default TUTTI_I_DOCENTISection;
