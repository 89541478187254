import React from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";

import maincolors from "../Colors/Color.js";
import Rectangle from "../Layout/Rectangle.jsx";
import ReactPlayer from "react-player";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    paddingTop: "1rem",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
}));

const ILCORSO = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper m-10">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          IL CORSO 2021
        </Typography>
        <Divider />
        <Grid container spacing={3} className="media centered-video">
          <Grid item className="card-width" xs={12}>
            <div className="player-wrapper">
              <ReactPlayer
                url={`https://player.vimeo.com/progressive_redirect/playback/670759992/rendition/1080p/1080p.mp4?loc=external&signature=ef7c159f146f2382c17664efc3d5f11a24f8d52ce0fb56b2b0b507d3d31dccd8`}
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
            <Typography variant="h5" color="initial" className="ilmond-title">
              Il corso 2021{" "}
            </Typography>
            <Typography className={`${classes.text}`}>
              La voce dei protagonisti
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ILCORSO;
