import React from "react";
import { Typography, Container, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
}));
const Thanks = (props) => {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <div className="page-wrapper-reg">
        <div className={classes.block}>
          <Typography>{props.text_top}</Typography>
          <Typography>{props.text_bottom}</Typography>
        </div>
      </div>
    </Container>
  );
};

export default Thanks;
