import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 60,
  },
  scrollTop: {
    position: "fixed",
    bottom: 20,
    marginLeft: -20,
    backgroundColor: "#00406c",
    color: "#fff",
    zIndex: 1000,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      right: "calc(50% - 27px)",
    },
    "&:hover": {
      backgroundColor: "#00406c",
      color: "#fff",
    },
  },
}));

const BackToTop = () => {
  const classes = useStyles();

  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);

  return (
    <div className={classes.wrapper}>
      <Fab
        className={classes.scrollTop}
        style={{ display: showScroll ? "flex" : "none" }}
        onClick={scrollTop}
      >
        <ExpandLessIcon fontSize="large" />
      </Fab>
    </div>
  );
};

export default BackToTop;
