import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import coverImage1 from "../../images/Icorsi1.jpg";
import coverImage2 from "../../images/I-corsi2_red.jpg";
import coverImage3 from "../../images/I-corsi3_red.jpg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cover: {
    maxWidth: "100%",
    height: "auto",
    width: "100%",
  },
  carousel: {
    marginBottom: 60,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 58,
    },
  },
}));

const CoverImage = () => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    autoplaySpeed: 10000,
    speed: 1500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.carousel}>
      <Slider {...settings} className="courses-slider">
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage1} alt="place-holder" />
        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage2} alt="place-holder" />
        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage3} alt="place-holder" />
        </div>
      </Slider>
      <div className="banner-overlay">
        <p color="initial" className="banner-text-primary">
          LE CHIAVI PER INTERPRETARE IL MONDO
        </p>
      </div>
    </div>
  );
};

export default CoverImage;
