import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Table from "./Table.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "auto",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "42%",
  },
  left: {
    height: "51vh",
    overflowY: "auto",
    marginLeft: 20,
    marginTop: -40,

    "& h1": {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 56,
      marginTop: 0,
    },
  },
  link: {
    color: "#ef9100",
  },
  privicy: {
    display: "block",
  },
  title: {
    textAlign: "center",
    marginTop: "15px !important",
  },
}));

const COOKIE = () => {
  const classes = useStyles();
  return (
    <div>
      <h1 className={classes.title}>COOKIE POLICY</h1>
      <p className={classes.privicy}>
        GEDI Digital S.r.l. <p className="strong">(“GEDI Digital”)</p> è una
        società del Gruppo GEDI, gruppo societario italiano operante nel settore
        dei media con attività nelle aree della stampa quotidiana e periodica,
        della radiofonia, della raccolta pubblicitaria e di Internet.
      </p>

      <p className={classes.privicy}>
        {" "}
        GEDI Digital con la presente informativa, resa ai sensi del D.Lgs.
        196/2003 come novellato dal D.Lgs. 101/2018{" "}
        <p className="strong">(“Codice Privacy”)</p> e del Regolamento (UE)
        2016/679 (<p className="strong">“GDPR”</p>), illustra cosa sono i cookie
        e le varie tipologie di cookie presenti nel sito internet
        <a href="https://scuoladilimes.it">https://scuoladilimes.it</a>.
      </p>

      <p className={classes.privicy}>
        Il Titolare del trattamento è GEDI Digital S.r.l., Via Ernesto Lugaro 15
        - 10126 Torino, Partita IVA 06979891006; e-mail:{" "}
        <a href="mailto:privacy@gedidigital.it"> privacy@gedidigital.it</a>.
      </p>
      <p className={classes.privicy}>
        All'interno del Gruppo GEDI è stato identificato un Data Protection
        Officer contattabile al seguente indirizzo e-mail:{" "}
        <a href="mailto:dpo@gedi.it">dpo@gedi.it</a>.
      </p>
      <p className={classes.privicy}>
        {" "}
        Nelle pagine relative ai singoli servizi offerti sono riportate le
        specifiche informative sul trattamento dei dati personali dirette a
        definire limiti e modalità del trattamento dei dati nell’ambito di
        ciascun servizio che integrano e completano la presente informativa.
      </p>
      <p className={classes.privicy}>
        Si osserva che la presente informativa non è estesa ad altri siti web
        eventualmente consultati dall'utente tramite navigazione dei link.
      </p>
      <h1>Cosa sono i Cookie?</h1>
      <p className={classes.privicy}>
        I Cookie sono pacchetti di informazioni inviate da un web server (es. il
        sito) al browser Internet dell’utente, da quest’ultimo memorizzati
        automaticamente sul computer e rinviati automaticamente al server ad
        ogni successivo accesso al sito.
      </p>
      <p className={classes.privicy}>
        Per default quasi tutti i browser web sono impostati per accettare
        automaticamente i cookie. Tipicamente i cookie possono essere
        installati:
      </p>

      <ul>
        <li>
          direttamente dal proprietario e/o responsabile del sito web (c.d.
          cookie di prima parte);
        </li>
        <li>
          da responsabili estranei al sito web visitato dall’utente (c.d. cookie
          di terza parte). Ove non diversamente specificato, si rammenta che
          questi cookie ricadono sotto la diretta ed esclusiva responsabilità
          dello stesso gestore. Ulteriori informazioni sulla privacy e sul loro
          uso sono reperibili direttamente sui siti dei rispettivi gestori.
        </li>
      </ul>
      <p className={classes.privicy}>
        Un sito web può utilizzare, anche in combinazione tra di loro, i
        seguenti tipi di cookie classificati in base alle indicazioni del
        Garante Privacy e dei Pareri emessi in ambito Europeo dal Gruppo di
        Lavoro ex art. 29.
      </p>
      <ul>
        <li>
          <p className="strong">Sessione</p> Sono cookie che non vengono
          memorizzati in modo persistente sul computer dell’utente e si
          cancellano con la chiusura del browser, sono strettamente limitati
          alla trasmissione di identificativi di sessione necessari per
          consentire l’esplorazione sicura ed efficiente del sito evitando il
          ricorso ad altre tecniche informatiche potenzialmente pregiudizievoli
          per la riservatezza della navigazione degli utenti.
        </li>
        <br />
        <li>
          <p className="strong">Persistenti</p> Sono cookie che rimangono
          memorizzati sul disco rigido del computer fino alla loro scadenza o
          cancellazione da parte degli utenti/visitatori. Tramite i cookie
          persistenti i visitatori che accedono al sito (o eventuali altri
          utenti che impiegano il medesimo computer) vengono automaticamente
          riconosciuti ad ogni visita. I visitatori possono impostare il browser
          del computer in modo tale che accetti/rifiuti tutti i cookie o
          visualizzi un avviso ogni qual volta viene proposto un cookie, per
          poter valutare se accettarlo o meno. L’utente può, comunque,
          modificare la configurazione predefinita e disabilitare i cookie (cioè
          bloccarli in via definitiva), impostando il livello di protezione più
          elevato.
        </li>
        <br />
        <li>
          <p className="strong">Tecnici</p> Sono i cookie utilizzati per
          autenticarsi, per usufruire di contenuti multimediali tipo flash
          player o per consentire la scelta della lingua di navigazione. In
          generale non è quindi necessario acquisire il consenso preventivo e
          informato dell'utente. Rientrano in questa fattispecie anche i cookie
          utilizzati per analizzare statisticamente gli accessi/le visite al
          sito solo se utilizzati esclusivamente per scopi statistici e tramite
          la raccolta di informazioni in forma aggregata.
        </li>
        <br />
        <li>
          <p className="strong">Non tecnici</p> Sono tutti i cookie usati per
          finalità di profilazione e marketing. Il loro utilizzo sui terminali
          degli utenti è vietato se questi non siano stati prima adeguatamente
          informati e non abbiano prestato al riguardo un valido consenso
          secondo la tecnica dell’opt-in. Questi tipi di cookie sono, a loro
          volta, raggruppabili in base alle funzioni che assolvono in:
        </li>
        <br />
        <ul>
          <li>
            <p className="strong">Analitycs.</p>Sono i cookie utilizzati per
            raccogliere e analizzare informazioni statistiche sugli accessi/le
            visite al sito web. In alcuni casi, associati ad altre informazioni
            quali le credenziali inserite per l’accesso ad aree riservate (il
            proprio indirizzo di posta elettronica e la password), possono
            essere utilizzate per profilare l’utente (abitudini personali, siti
            visitati, contenuti scaricati, tipi di interazioni effettuate,
            ecc.).
          </li>
          <br />
          <li>
            <p className="strong">Widgets.</p> Rientrano in questa categoria
            tutti quei componenti grafici di una interfaccia utente di un
            programma, che ha lo scopo di facilitare l'utente nell'interazione
            con il programma stesso. A titolo esemplificativo sono widget i
            cookie di facebook, twitter.
          </li>
          <br />
          <li>
            <p className="strong">Advertsing.</p> Rientrano in questa categoria
            i cookie utilizzati per fare pubblicità all’interno di un sito come
            Google, Tradedoubler.
          </li>
          <br />
          <li>
            <p className="strong">Web beacons.</p> Rientrano in questa categoria
            i frammenti di codice che consentono a un sito web di trasferire o
            raccogliere informazioni attraverso la richiesta di un'immagine
            grafica. I siti web possono utilizzarli per diversi fini, quali
            l'analisi dell'uso dei siti web, attività di controllo e
            reportistica sulle pubblicità e la personalizzazione di pubblicità e
            contenuti.
          </li>
        </ul>
      </ul>
      <h1>I cookie presenti sul sito</h1>
      <p className={classes.privicy}>
        Questo sito non utilizza cookie per la trasmissione di informazioni di
        carattere personale, cookie persistenti di alcun tipo né i cc.dd. cookie
        di profilazione, utilizzati al fine di creare profili relativi
        all’utente e inviare messaggi pubblicitari in linea con le preferenze
        manifestate dallo stesso nell'ambito della navigazione in rete.
      </p>
      <p className={classes.privicy}>
        L’uso di cc.dd. cookie di sessione (che non vengono memorizzati in modo
        persistente sul computer dell’utente e svaniscono con la chiusura del
        browser) è, invece, strettamente limitato alla trasmissione di
        identificativi di sessione (costituiti da numeri causali generati dal
        server) necessari per consentire l’esplorazione sicura ed efficiente del
        sito.
      </p>
      <p className={classes.privicy}>
        I cookie di sessione utilizzati in questo sito evitano il ricorso ad
        altre tecniche informatiche potenzialmente pregiudizievoli per la
        riservatezza della navigazione degli utenti e non consentono
        l’acquisizione di dati personali identificativi dell’utente.
      </p>

      <Table />

      <br />
      <h1>Come disabilitare i cookie mediante configurazione del browser</h1>
      <p className={classes.privicy}>
        Può impostare il suo dispositivo in modo da accettare tutti i cookie,
        ricevere notifiche quando un cookie viene inviato o non ricevere affatto
        cookie.
      </p>
      <p className={classes.privicy}>
        Con riferimento ai cookie sopra elencati, l’utente può bloccarli o
        cancellarli (in tutto o in parte) attraverso le specifiche funzioni del
        proprio programma di navigazione (c.d. browser); tuttavia, nell’ipotesi
        in cui tutti o alcuni dei cookie vengano disabilitati è possibile che il
        sito risulti non consultabile o che alcuni servizi o determinate
        funzioni del sito non siano disponibili o non funzionino correttamente.
      </p>
      <p className={classes.privicy}>
        Per una ottimale gestione, attivazione e disattivazione dei cookie a
        seconda del browser utilizzato per visualizzare il sito le forniamo i
        seguenti collegamenti a rubriche di aiuto dei browser più utilizzati:{" "}
        <a href="https://support.microsoft.com/en-us/topic/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
          Internet Explorer,
        </a>{" "}
        <a href="https://support.apple.com/de-de/guide/safari/sfri11471/mac">
          Safari,
        </a>{" "}
        <a href="https://support.google.com/chrome/answer/95647?hl=it&hlrm=it">
           Chrome,
        </a>{" "}
        <a href="https://support.mozilla.org/it/kb/protezione-antitracciamento-avanzata-firefox-desktop[…]irectslug=Attivare+e+disattivare+i+cookie&redirectlocale=it">
          Firefox,
        </a>{" "}
        <a href="https://help.opera.com/en/latest/web-preferences/#cookies">
          Opera.
        </a>{" "}
      </p>
    </div>
  );
};

export default COOKIE;
