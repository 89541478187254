import React from "react";
import { Typography, makeStyles, Divider, Grid } from "@material-ui/core";

import Rectangle from "../Layout/Rectangle.jsx";

import Block from "./Block.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },

  blog: {
    padding: theme.spacing(6, 12.5),
    backgroundColor: "#eff0f1",
    "& h4": {
      marginBottom: 8,
    },
    "& h6": {
      marginBottom: 16,
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  auth: {
    color: "#3c3c3b",
  },
  div: {
    marginBottom: 112,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
}));

const LIMESPERLEAZIENDESection = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          LIMES PER LE AZIENDE
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Le chiavi di lettura puramente economico-finanziarie non sono più
              sufficienti a navigare la complessità internazionale. La Scuola di
              Limes fornisce consulenze su misura per le imprese che proiettano
              se stesse e il paese in un mondo sempre più competitivo.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="section-wrapper">
        <Block />
      </div>
    </div>
  );
};

export default LIMESPERLEAZIENDESection;
