import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Table from "./Table.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "auto",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "42%",
  },
  left: {
    height: "51vh",
    overflowY: "auto",
    marginLeft: 20,
    marginTop: -40,

    "& h1": {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 56,
      marginTop: 0,
    },
  },
  link: {
    color: "#ef9100",
  },
  privicy: {
    display: "block",
  },
  title: {
    textAlign: "center",
    marginTop: "15px !important",
  },
  space: {
    paddingLeft: 40,
    display: "block",
  },
}));

const Brochure = () => {
  const classes = useStyles();
  return (
    <div>
      <h1 className={classes.title}>
        INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI - BROCHURE
      </h1>
      <p className="strong">
        <p>Introduzione</p>
      </p>
      <p className={classes.privicy}>
        La presente informativa è resa da{" "}
        <p className="strong">GEDI Periodici e Servizi S.p.A.</p>, con sede
        legale in Via Ernesto Lugaro, 15 – 10126, Torino, società del Gruppo
        GEDI, gruppo societario italiano operante nel settore dei media con
        attività nelle aree della stampa quotidiana e periodica, della
        radiofonia, della raccolta pubblicitaria e di Internet (per brevità “il{" "}
        <p className="strong">Gruppo</p>”), in qualità di titolare del
        trattamento (di seguito “il <p className="strong">Titolare</p>” o “
        <p className="strong">GPS</p>”), ai sensi dell’art. 13 del Regolamento
        (UE) 2016/679 (di seguito <p className="strong">”GDPR”</p>
        ).
      </p>
      <p className={classes.privicy}>
        Tale informativa è fornita allo scopo di illustrarle le finalità e le
        modalità con cui GPS raccoglie e tratta i dati personali da lei forniti,
        quali categorie di dati sono oggetto di trattamento, quali sono i
        diritti degli interessati e come possono essere esercitati.
      </p>
      <p className="strong">
        <p>Categorie di dati personali oggetto di trattamento</p>
      </p>
      <p className={classes.privicy}>
        Qualora lei richieda l’invio della brochure della Scuola di Limes al suo
        indirizzo di posta elettronica, GPS tratterà i suoi seguenti dati
        personali: nome, cognome e indirizzo di posta elettronica.
      </p>
      <p className={classes.privicy}>
      Il conferimento dei dati personali sopra indicati è necessario per permetterci di fornirle il materiale
      richiesto; in assenza, GPS non potrà dare seguito alla sua richiesta.
      </p>
      <p className="strong">
        <p>Finalità e base giuridica del trattamento</p>
      </p>
      <p className={classes.privicy}>
        GPS tratterà i suoi dati personali ai sensi dell’art. 6, paragrafo 1,
        lett. b) del GDPR al fine di inviarle la brochure da lei richiesta.
      </p>
      <p className={classes.privicy}>
        Inoltre, qualora lei ci fornisca un suo espresso e specifico consenso,
        GPS tratterà i suoi dati personali per l’invio di comunicazioni
        commerciali sulle attività della Scuola e su propri prodotti e servizi,
        per iniziative promozionali (presenti e future), per svolgere indagini
        di mercato e analisi del livello di soddisfazione della clientela. Lei
        ha il diritto di revocare, in qualsiasi momento, il consenso prestato
        per il trattamento dei dati personali per questa finalità scrivendo a{" "}
        <a href="mailto:privacy@gedigps.it">privacy@gedigps.it </a>-
        specificando nell’oggetto della comunicazione “
        <p className="strong">Scuola di LIMES</p>”.
      </p>
      <p className="strong">
        <p>Durata del trattamento e periodo di conservazione dei dati</p>
      </p>
      <p className={classes.privicy}>
        I suoi dati saranno trattati solo per il tempo strettamente necessario
        per le finalità sopra menzionate.
      </p>
      <p className={classes.privicy}>
        Qualora lei abbia conferito il suo consenso per il trattamento dei dati
        forniti per finalità di marketing, tali dati saranno trattati fino alla
        revoca del consenso.
      </p>

      <p className="strong">
        <p>
          Categorie di soggetti ai quali i dati personali possono essere
          comunicati e finalità della comunicazione
        </p>
      </p>
      <p className={classes.privicy}>
        Alcuni trattamenti di dati personali potranno essere effettuati anche da
        soggetti terzi ai quali GPS affida la fornitura di alcuni servizi. In
        tal caso, questi soggetti saranno designati Responsabili del
        trattamento, ai sensi dell’art. 28 del GDPR, e riceveranno adeguate
        istruzioni operative, con particolare riferimento all’adozione delle
        misure tecniche e organizzative idonee a garantire la sicurezza dei dati
        oggetto di trattamento, al fine di poter garantire la riservatezza e la
        sicurezza dei dati.
      </p>
      <p className={classes.privicy}>
        L’elenco dei Responsabili del trattamento può essere richiesto a GPS
        scrivendo a <a href="mailto:privacy@gedigps.it">privacy@gedigps.it </a>{" "}
        - specificando nell’oggetto della comunicazione “
        <p className="strong">Scuola di LIMES</p>”.
      </p>
      <p className={classes.privicy}>
        Non è prevista alcuna forma di diffusione dei suoi dati a soggetti
        indeterminati.
      </p>
      <p className="strong">
        <p>Trattamento dei dati fuori dell’Unione europea</p>
      </p>

      <p className={classes.privicy}>
        GPS potrebbe trasferire i suoi dati personali a terze parti ubicate in
        Stati non appartenenti all'Unione Europea ovvero allo Spazio Economico
        Europeo. In tale circostanza, tali terze parti saranno nominate
        Responsabili del trattamento ai sensi e per gli effetti di cui
        all’articolo 28 del GDPR e il trasferimento dei suoi dati personali a
        tali soggetti, limitatamente allo svolgimento di specifiche attività di
        trattamento, sarà regolato in conformità a quanto previsto dal capo V
        del GDPR. Saranno quindi adottate tutte le cautele necessarie al fine di
        garantire adeguata protezione dei suoi dati personali basando tale
        trasferimento: (a) su decisioni di adeguatezza dei paesi terzi
        destinatari espresse dalla Commissione Europea; (b) su garanzie adeguate
        espresse dal soggetto terzo destinatario ai sensi dell’articolo 46 del
        GDPR; (c) sull’adozione di norme vincolanti d’impresa, c.d. corporate
        binding rules. Gli utenti possono richiedere informazioni in merito a
        tali garanzie inoltrando richiesta a{" "}
        <a href="mailto:privacy@gedigps.it"> privacy@gedigps.it </a> -
        specificando nell’oggetto della comunicazione “
        <p className="strong">Scuola di LIMES</p>”.
      </p>
      <p className={classes.privicy}>
        A seguito della pronuncia della Corte di Giustizia dell’Unione Europea
        del 16 luglio 2020 nella causa C311/18, con riferimento ai paesi terzi
        che non sono destinatari di una delle decisioni di adeguatezza da parte
        della Commissione Europea, GPS effettua valutazioni specifiche in merito
        alle garanzie supplementari da adottare, al fine di prevenire
        interferenze da parte di autorità pubbliche locali che possano
        pregiudicare il livello di essenziale equivalenza di protezione dei dati
        ivi trasferiti, rispetto al livello goduto in base al diritto europeo.
      </p>
      <p className={classes.privicy}>
        <p className="strong">Diritti degli interessati</p>
      </p>
      <p className={classes.privicy}>
        La informiamo che, in relazione al trattamento dei suoi dati personali,
        potrà esercitare i diritti di cui agli articoli 15 e ss del GDPR
        (accesso, portabilità, opposizione, rettifica, limitazione e
        cancellazione), nonché il diritto di revoca del consenso (ex art. 7 del
        GDPR), rivolgendosi al Titolare del trattamento tramite e-mail
        all’indirizzo
        <a href="mailto:privacy@gedigps.it"> privacy@gedigps.it</a> -
        specificando nell’oggetto della comunicazione “
        <p className="strong">Scuola di LIMES</p>”.
      </p>
      <p className={classes.privicy}>
        Le comunichiamo, inoltre, che all’interno del Gruppo è stato
        identificato un Data Protection Officer, contattabile al seguente
        indirizzo mail:<a href="mailto:dpo@gedi.it"> dpo@gedi.it</a>.
      </p>
      <p className={classes.privicy}>
        La informiamo infine che ai sensi della disciplina vigente può proporre
        eventuali reclami riguardanti i trattamenti di suoi dati personali al
        Garante per la protezione dei dati personali.
      </p>
    </div>
  );
};

export default Brochure;
