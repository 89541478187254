import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "auto",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "42%",
  },
  left: {
    height: "51vh",
    overflowY: "auto",
    marginLeft: 20,
    marginTop: -40,

    "& h1": {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 56,
      marginTop: 0,
    },
  },
  link: {
    color: "#ef9100",
  },
  privicy: {
    display: "block",
  },
  title: {
    textAlign: "center",
    marginTop: "15px !important",
  },
  dash: {
    listStyleType: "none",
    textIndent: "-11px",
  },
  alfa: {
    "& li::marker": {
      fontWeight: "bold",
    },
  },
  right: {
    float: "right",
    paddingRight: "10px",
    "& span": {
      display: "block",
    },
  },
  text: {
    float: "left",
  },
}));

const SERVIZIO = () => {
  const classes = useStyles();
  return (
    <div>
      <h1 className={classes.title}>CONDIZIONI DI SERVIZIO</h1>
      <ol className={classes.alfa}>
        <li>
          <p className={classes.privicy}>
            {" "}
            <p className="strong">Ambito di applicazione</p>
          </p>
          <p className={classes.privicy}>
            Il presente documento indica le condizioni di servizio (di seguito “
            <p className="strong">Condizioni di servizio</p>”) che disciplinano
            l’acquisto e la partecipazione ai corsi di formazione on line tenuti
            dalla Scuola di Limes (di seguito “<p className="strong">Corsi”</p>)
            sotto il controllo e la direzione scientifica di GEDI Periodici e
            Servizi S.p.A., con sede legale in Via Ernesto Lugaro 15 - 10126
            Torino, P. Iva 12546800017 (di seguito “
            <p className="strong">GPS</p>“), società del Gruppo GEDI soggetta a
            direzione e coordinamento di GEDI Gruppo Editoriale S.p.A. I Corsi
            sono offerti ed erogati tramite il sito{" "}
            <a href="https://www.scuoladilimes.it">www.scuoladilimes.it</a> (di
            seguito il “<p className="strong">Sito</p>“) di titolarità di GEDI
            Digital S.r.l., con sede legale in Via Ernesto Lugaro 15 - 10126
            Torino, P. Iva 06979891006 (di seguito “
            <p className="strong">GEDI Digital</p>”), anch’essa società del
            Gruppo GEDI soggetta a direzione e coordinamento di GEDI Gruppo
            Editoriale S.p.A.
          </p>
          <p className={classes.privicy}>
            Le attività formative e il programma dei singoli Corsi sono resi
            noti con la pubblicazione sul Sito della Scuola di Limes. Al termine
            dei Corsi saranno rilasciati gli attestati di partecipazione.
          </p>
        </li>
        <li>
          <p className="strong">Finalità dei Corsi </p>
          <p className={classes.privicy}>
            L’obiettivo dei Corsi è maturare nei partecipanti la capacità di
            scegliere e decidere, sulla base di analisi fondate e per perseguire
            obiettivi definiti, in qualsiasi situazione ed entro qualsiasi
            istituzione, struttura o azienda proiettata nel mondo.
          </p>
          <p className={classes.privicy}>
            Possono essere pre-esperienza, quindi rivolti a studenti senza o con
            limitata esperienza lavorativa, o post esperienza.
          </p>
          <p className={classes.privicy}>
            La partecipazione ai Corsi è a pagamento, previo superamento di una
            attenta selezione dei candidati da parte della Direzione della
            Scuola. I Corsi saranno attivati solo al raggiungimento del numero
            minimo di partecipanti reso noto nel Programma del singolo Corso.
          </p>
        </li>
        <li>
          <p className="strong">
            Modello didattico, attività formative. Programma delle lezioni.{" "}
          </p>
          <h4>Modello didattico</h4>
          <p className={classes.privicy}>
            L’attività didattica è volta a padroneggiare il fattore umano in
            geopolitica, indispensabile chiave per analizzare l’attualità;
            calarsi nella mentalità strategica delle grandi potenze;
            impadronirsi degli strumenti con cui i decisori ragionano; elaborare
            un punto di vista autonomo su come proteggere e avanzare il nostro
            interesse nazionale. A tal fine il modello didattico prevede un
            insegnamento attivo con forte coinvolgimento del partecipante,
            attraverso lezioni, incontri, dibattiti, testimonianze,
            esercitazioni che vedranno coinvolte personalità di rilievo
            nazionale ed internazionale e inoltre lavori individuali o di
            gruppo, guidati dai mentor assegnati dalla Direzione della Scuola.
            L’attività didattica si svolgerà in modalità a distanza sulla
            piattaforma di e-learning presente sul Sito. I partecipanti saranno
            divisi fino ad un massimo di tre classi in funzione del numero di
            candidature accettate. Le lezioni saranno rese disponibili in live
            streaming. La Direzione della Scuola, qualora ne sussistano le
            condizioni, si riserva la facoltà di selezionare tra i candidati che
            ne hanno fatto richiesta, a sua insindacabile discrezione, coloro
            che potranno assistere in presenza alle lezioni che si terranno
            presso la sede di Roma per un massimo di 15 (quindici) persone a
            classe a rotazione su tutti i partecipanti, in modo da assicurare la
            più ampia partecipazione ai richiedenti. Il programma delle attività
            formative per ciascun Corso (di seguito il “
            <p className="strong">Programma</p>”) è pubblicato sul sito della
            Scuola di Limes. I Corsi non prevedono il riconoscimento di crediti
            formativi.
          </p>
          <h4>Stage e attività assimilabili </h4>
          <p className={classes.privicy}>
            Qualora nell’ambito del Programma dello specifico Corso sia stata
            prevista, a completamento del percorso intrapreso, un’esperienza
            formativa presso realtà istituzionali e imprenditoriali, di livello
            nazionale ed internazionale, la Direzione della Scuola provvederà a
            selezionare i partecipanti più meritevoli, sulla base della propria
            valutazione discrezionale dei risultati conseguiti nell’ambito del
            Corso.
          </p>
          <p className={classes.privicy}>
            Lo stage si svolgerà in un periodo successivo alla conclusione del
            Corso, sulla base di un progetto formativo concordato tra il
            partecipante, l’istituzione ospitante e il Direttore del corso.
          </p>
        </li>
        <li>
          <p className="strong">Requisiti e colloquio di ammissione.</p>
          <p className={classes.privicy}>
            La partecipazione ai Corsi è riservata a coloro che sono in possesso
            di uno dei seguenti titoli di studio:
          </p>
          <ul className={classes.dash}>
            <li>
              <p className={classes.privicy}>
                - laurea (triennale o magistrale);
              </p>
            </li>
            <li>
              {" "}
              <p className={classes.privicy}>
                - altro titolo di studio conseguito all'estero e riconosciuto
                idoneo.
              </p>
            </li>
          </ul>

          <p className={classes.privicy}>
            È altresì necessario avere una adeguata conoscenza della lingua
            inglese per consentire una proficua partecipazione al programma
            (pari almeno al livello C1 o equivalente) che sarà oggetto di
            verifica nel corso del colloquio.
          </p>
          <p className={classes.privicy}>
            L’ammissione ai Corsi è subordinata alla presentazione della propria
            candidatura mediante il form predisposto sul Sito, allegando il
            Curriculum Vitae attestante il possesso dei requisiti predetti, e al
            superamento di una selezione che si svolgerà sulla base di un
            colloquio individuale con la Direzione della Scuola e/o di un test
            preselettivo che sarà somministrato secondo le indicazioni di volta
            in volta fornite dalla Direzione della Scuola.
          </p>
          <p className={classes.privicy}>
            Le informazioni relative allo svolgimento del colloquio, che potrà
            tenersi anche con mezzi di comunicazione a distanza, saranno rese
            note con apposita comunicazione all’indirizzo e-mail indicato
            all’atto della candidatura.
          </p>
          <p className={classes.privicy}>
            Il superamento della selezione e la conseguente ammissione al Corso,
            nonché l’eventuale possibilità di partecipare alle lezioni in
            presenza presso la sede di Roma, saranno comunicate a mezzo e-mail
            inviata all’indirizzo fornito all’atto della candidatura.
          </p>
          <p className={classes.privicy}>
            L’iscrizione dovrà essere perfezionata con il versamento della quota
            di partecipazione nei termini e secondo quanto disposto al
            successivo articolo 11.
          </p>
          <p className={classes.privicy}>
            La domanda di iscrizione dovrà indicare l’indirizzo e-mail a cui il
            partecipante dovrà ricevere tutte le comunicazioni inerenti al
            Corso. Salvo modifica da comunicarsi per iscritto e nelle modalità
            idonee, nessun’altro indirizzo sarà utilizzato o considerato valido
            da parte della Direzione della Scuola. Qualora venga accertata la
            falsità dei dati forniti, la Direzione si riserva il diritto di
            impedire/sospendere l’erogazione del corso al partecipante.
          </p>
        </li>
        <li>
          <p className="strong">
            Frequenza dei Corsi. Accesso alla piattaforma e account.
          </p>
          <p className={classes.privicy}>
            La frequenza è riservata ai candidati che hanno superato la
            selezione e hanno provveduto a perfezionare l’iscrizione ai Corsi
            mediante il pagamento della quota di iscrizione.
          </p>
          <p className={classes.privicy}>
            La frequenza dei Corsi on line è obbligatoria e la rilevazione della
            frequenza sarà effettuata secondo le modalità indicate dalla
            Direzione dei Corsi.
          </p>

          <p className={classes.privicy}>
            Per la fruizione delle video-lezioni e del materiale didattico reso
            disponibile dai docenti, è necessaria l’attivazione di un account
            personale. La username e la password associati all’account sono
            strettamente personali e il partecipante si impegna a custodirli con
            ogni mezzo idoneo al fine di impedirne l’utilizzo da parte di terzi
            non autorizzati; il partecipante è tenuto ad informare senza ritardo
            la Direzione nel caso in cui la riservatezza delle proprie
            credenziali di accesso risulti compromessa per qualsiasi motivo
            (furto, smarrimento, etc.). Considerata la natura strettamente
            personale dell’account del partecipante, è fatto espresso divieto a
            quest’ultimo di cedere a terzi l’utilizzo dello stesso.
          </p>
          <p className={classes.privicy}>
            La Direzione si riserva il potere di sospendere o chiudere
            definitivamente l’account qualora, a suo insindacabile giudizio, le
            attività poste in essere attraverso di esso violino la legge e/o le
            presenti Condizioni di servizio. Sono fatti salvi gli ulteriori
            rimedi stabiliti dalla legge, compreso il diritto alla risoluzione
            del rapporto e al risarcimento dei danni.
          </p>
        </li>
        <li>
          <p className="strong">Tutela del diritto d’autore</p>
          <p className={classes.privicy}>
            GPS, GEDI Digital e i loro aventi causa, ciascuno a proprio titolo,
            hanno l’esclusiva proprietà dei contenuti dei Corsi e del materiale
            didattico relativo nonché di tutte le parti del Sito e dei contenuti
            sullo stesso presenti, quali a titolo meramente esemplificativo e
            non esaustivo, i loghi, le immagini, i testi, i video: ne consegue,
            in applicazione della Legge 22.04.1941 n° 633 (Legge sul diritto
            d’autore), l’assoluto divieto d’uso commerciale da parte di terzi,
            di riproduzione totale o parziale, di rielaborazione e di
            trasmissione sotto qualunque forma e con qualsiasi modalità, salvo
            preventiva autorizzazione scritta di GPS e/o GEDI Digital e/o del
            titolare dei diritti.
          </p>
          <p className={classes.privicy}>
            È altresì vietato copiare, distribuire, pubblicare o altrimenti
            utilizzare o sfruttare i contenuti presenti sul Sito e il materiale
            didattico fornito ai partecipanti senza il preventivo consenso
            scritto di GPS e/o GEDI Digital e/o del titolare dei diritti.
          </p>
        </li>
        <li>
          <p className="strong">Consegna dell’attestato di partecipazione</p>
          <p className={classes.privicy}>
            Al termine del Corso, sarà consegnato un attestato di partecipazione
            attestante le conoscenze, le abilità e le attitudini personali
            acquisite all’esito della frequenza.
          </p>
        </li>
        <li>
          <p className="strong">Norme di comportamento</p>
          <p className={classes.privicy}>
            Tutti coloro che saranno ammessi alla frequentazione dei Corsi della
            Scuola di Limes sono tenuti a soddisfare gli impegni formativi
            assunti e a versare la quota di partecipazione prevista. Sono
            inoltre tenuti ad osservare comportamenti rispettosi del proficuo
            svolgimento delle attività didattiche e della integrità personale,
            nonché della dignità altrui e dell’integrità del decoro dei luoghi
            nei quali si svolge l’insegnamento.
          </p>
          <p className={classes.privicy}>
            La violazione dei doveri di comportamento di cui al comma precedente
            del presente articolo comporta, ad insindacabile giudizio della
            Direzione, l’esclusione dalla frequenza del Corso, senza che ciò
            implichi la restituzione dell’importo versato per la partecipazione
            allo stesso, ferma ogni altra conseguenza di legge e responsabilità
            verso GPS e i terzi.
          </p>
        </li>
        <li>
          <p className="strong">Durata</p>
          <p className={classes.privicy}>
            La durata dei Corsi è di 20 (venti) settimane. L’avvio dell’attività
            didattica avviene nel mese di aprile. Le attività formative si
            svolgeranno nelle giornate del venerdì e sabato per un totale di 124
            (centoventiquattro) ore di lezione. Ogni modifica a tale previsione
            sarà resa nota al momento della pubblicazione del Programma.
          </p>
        </li>
        <li>
          <p className="strong">Direzione dei Corsi</p>
          <p className={classes.privicy}>
            I Corsi si svolgono sotto la direzione e il controllo di GPS che
            affida la direzione e il coordinamento scientifico al Direttore
            della Scuola. Il Direttore, anche con l’ausilio di collaboratori,
            assicura lo svolgimento di tutte le attività formative definite nel
            Programma dei Corsi.
          </p>
          <p className={classes.privicy}>
            Il Direttore può nominare un coordinatore per la cura degli aspetti
            organizzativi relativi a ciascun Corso.
          </p>
        </li>
        <li>
          <p className="strong">Quota d’iscrizione</p>
          <p className={classes.privicy}>
            La quota di iscrizione al Corso è di euro 5.500,00
            (cinquemilacinquecento,00) IVA inclusa. Il pagamento in unica
            soluzione, da effettuarsi entro e non oltre 7 (sette) giorni dalla
            comunicazione di avvenuto superamento della selezione, darà diritto
            all'attivazione di un abbonamento alla rivista cartacea LIMES per 12
            (dodici) mesi, nonché ad una riduzione pari al 10% della quota.
          </p>
          <p className={classes.privicy}>
            La quota di iscrizione potrà essere versata anche in due rate di
            pari importo. In tal caso, la prima rata dovrà essere corrisposta
            entro 7 (sette) giorni dalla comunicazione del superamento della
            selezione e la successiva dovrà essere corrisposta entro e non oltre
            il 5 settembre 2022.
          </p>
        </li>
        <li>
          <p className="strong">Modifica al programma del Corso</p>
          <p className={classes.privicy}>
            Il Programma dei Corsi è quello reso noto tramite pubblicazione sul
            Sito. Con separata comunicazione inviata all’indirizzo e-mail del
            partecipante, saranno indicate le date e l’orario delle lezioni.
          </p>
          <p className={classes.privicy}>
            La Scuola di Limes si riserva la facoltà, a sua discrezione, di
            modificare la data, l’orario, gli argomenti o i docenti di ciascun
            corso senza che ciò determini alcuna responsabilità a carico della
            Scuola. Della modifica sarà data notizia con comunicazione a mezzo
            e-mail.
          </p>
          <p className={classes.privicy}>
            Per problemi di forza maggiore o per mancato raggiungimento del
            numero minimo di iscritti, la Scuola di Limes ha inoltre la facoltà
            di annullare il Corso. In questi casi provvederà a darne tempestiva
            comunicazione al partecipante e a rimborsare la quota di iscrizione
            versata entro 90 (novanta) giorni.
          </p>
        </li>
        <li>
          <p className="strong">Trattamento dei dati personali </p>
          <p className={classes.privicy}>
            GPS, in qualità di Titolare, tratterà i dati personali dei candidati
            e dei partecipanti ai Corsi in conformità alle disposizioni del
            D.Lgs. n. 196/2003 come novellato dal D.Lgs. 101/2018{" "}
            <p className="strong">(“Codice Privacy“)</p>, del Regolamento (UE)
            2016/679 del Parlamento Europeo e del Consiglio del 27 aprile 2016
            concernente la tutela delle persone fisiche con riguardo al
            trattamento dei dati personali e la libera circolazione di tali dati
            (“GDPR”) e in conformità alle informative rese, ai sensi e per gli
            effetti di cui all’art. 13 del GDPR, all’atto dell’accesso ai
            servizi e disponibili al seguente link{" "}
            <a href="/paginadocumenti">Privacy</a>.
          </p>
        </li>
        <li>
          <p className="strong">Accettazione delle Condizioni di servizio</p>
          <p className={classes.privicy}>
            La candidatura ai fini della selezione comporta la completa
            accettazione, senza riserve, delle presenti Condizioni di servizio.
          </p>
        </li>
        <li>
          <p className="strong">
            Pubblicazione e validità delle Condizioni di servizio
          </p>
          <p className={classes.privicy}>
            Le presenti Condizioni di servizio sono valide ed efficaci dalla
            data di pubblicazione. GPS si riserva il diritto di apportarvi
            modifiche in qualsiasi momento, che si riterranno efficaci dalla
            loro pubblicazione sul Sito. Sono fatti salvi i diritti acquisiti da
            coloro che hanno già perfezionato l’iscrizione con il versamento
            della relativa quota.
          </p>
        </li>
        <li>
          <p className="strong">Modifica al programma del Corso</p>
          <p className={classes.privicy}>
            È riconosciuto agli iscritti, ai sensi e per gli effetti degli artt.
            52 e ss. del D. Lgs. 6 settembre 2005, n° 206 (Codice del consumo),
            il diritto di recedere entro 14 (quattordici) giorni dal
            perfezionamento dell’iscrizione, ossia entro 14 (quattordici) giorni
            dal versamento della quota di iscrizione o della prima rata.
          </p>
          <p className={classes.privicy}>
            Il recesso potrà essere esercitato in una delle forme previste
            dall’art. 54 tra le quali la compilazione del modulo appositamente
            predisposto e disponibile in calce alle presenti Condizioni di
            servizio.
          </p>
          <p className={classes.privicy}>
            Le somme già versate verranno restituite al contraente entro 14
            (quattordici) giorni dal recesso, tramite bonifico su un c/c
            bancario il cui codice IBAN dovrà essere indicato nella
            comunicazione di recesso.
          </p>
          <p className={classes.privicy}>
            Eventuali eccezioni al diritto di recesso, ove previste da Codice
            del consumo – decreto legislativo 6 settembre 2005, n. 206, saranno
            comunicate in sede di offerta prima dell’acquisto del Corso.
          </p>
        </li>
        <li>
          <p className="strong">Legge applicabile</p>
          <p className={classes.privicy}>
            Le presenti Condizioni di servizio sono disciplinate dalla legge
            italiana. In caso di controversie avente ad oggetto la formazione,
            l’interpretazione, l’esecuzione, la validità o la cessazione delle
            Condizioni di servizio, ove il partecipante sia qualificabile come
            consumatore, sarà competente il giudice del luogo di residenza o
            domicilio del consumatore, se ubicato in Italia. In tutti gli altri
            casi sarà competente a giudicare in via esclusiva il foro di Torino.
          </p>
        </li>
      </ol>
      <p className={classes.privicy}>
        RECESSO DEL CONSUMATORE - MODULO DI RECESSO AI SENSI DELL’ARTICOLO 49,
        COMMA 1, LETT. H DEL CODICE DEL CONSUMO (compilare e restituire il
        presente modulo solo se si desidera recedere dal Corso della Scuola di
        Limes entro 14 giorni dall’iscrizione. Allegare documento di
        riconoscimento) -
      </p>
      <p className={`${classes.privicy} ${classes.right}`}>
        <span> GEDI Periodici e Servizi S.p.A.</span>
        <span> Via Ernesto Lugaro, 15.</span>
        <span> 10126 - Torino</span>
        <p>
          A mezzo e-mail:{" "}
          <a href="mailto:info@scuoladilimes.it">info@scuoladilimes.it</a>
        </p>
      </p>
      <div className={classes.text}>
        {" "}
        <p className={`${classes.privicy}`}>
          Con la presente io sottoscritto (nome e cognome del partecipante)
          notifico il recesso dall’acquisto del Corso di formazione (titolo del
          Corso) della Scuola di Limes effettuato il (data di versamento della
          quota) a mio nome.
        </p>
        <p className={`${classes.privicy}`}>
          A tal fine fornisco i seguenti dati:
        </p>
        <ul>
          <li>codice fiscale</li>
          <li>indirizzo e-mail</li>
          <li>IBAN (per rimborso della quota di iscrizione)</li>
        </ul>
        <p className={`${classes.privicy}`}>
          Allego documento di riconoscimento debitamente sottoscritto
        </p>
        <p className={`${classes.privicy}`}>gg.mm.aaaa</p>
      </div>

      <p className={`${classes.privicy} ${classes.right}`}>
        <span>Firma leggibile</span>
      </p>
    </div>
  );
};

export default SERVIZIO;
