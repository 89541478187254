import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";
import { Link } from "react-router-dom";
import maincolors from "../Colors/Color.js";

import DocintiImages from "../../helpers/DocintiImages";
const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,

      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 400,
  },
  root: {
    boxShadow: "none",
    borderRadius: 0,
  },
  cardContent: {
    padding: "16px 0",
    paddingBottom: 60,
  },
  text: {
    paddingTop: "1rem",

    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 112,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
}));

const docents = [
  {
    firstname: "Lucio",
    lastname: "Caracciolo",
    description:
      "Direttore di Limes, che ha fondato nel 1993, editorialista per la Repubblica e la Stampa, insegna Studi strategici alla LUISS Guido Carli e Geopolitica all’Università Vita e Salute – San Raffaele.",
    auth: "Direttore",
    image: DocintiImages["Lucio-Caracciolo.jpg"]
  },

  {
    firstname: "Laura",
    lastname: "Canali",
    description:
      "Esperta in cartografia geopolitica, responsabile della cartografia e delle copertine di Limes. Ha esposto i suoi lavori, tra gli altri, alla Biennale di Venezia 2012, al MAXXI B.A.S.E. e al Palazzo Ducale di Genova.",
    auth: "Cartografa",
    image: DocintiImages["Laura-Canali.jpg"]
  },
  {
    firstname: "Federico",
    lastname: "Petroni",
    description:
      "Consigliere redazionale di Limes, responsabile del Limes Club Bologna e co-fondatore di iMerica.",
    auth: "Coordinatore didattico",
    image: DocintiImages["Federico-Petroni.jpg"]
  },
  {
    firstname: "Giorgio",
    lastname: "Cuscito",
    description:
      "Consigliere redazionale di Limes, analista, studioso di geopolitica della Cina e dell’Indo-Pacifico. Curatore del Bollettino Imperiale su limesonline.com",
    auth: "Coordinatore club alumni e relazioni esterne",
    image: DocintiImages["Giorgio-Cuscito.jpg"]
  },
  {
    firstname: "Orietta",
    lastname: "Moscatelli",
    description: `Esperta di Russia e spazio ex Sovietico, negli anni Novanta ha vissuto e lavorato a Mosca, dove torna regolarmente. Collabora con Limes dal 2004. Dopo Mosca, Londra e Lione ora vive a Roma. È caporedattore Esteri dell'agenzia Askanews. Co-autrice dei libri: "Cecenia" e "Ucraina, anatomia di un terremoto".`,
    auth: "Responsabile esercitazioni",
    image: DocintiImages["Orietta-Moscatelli.jpg"]
  },
];

const CardSection = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
        CHI INSEGNA
        </Typography>

        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Tutte le lezioni sono tenute o coordinate dalla Direzione della
              Scuola, che seleziona specifiche testimonianze italiane e
              straniere dalla vasta rete di Limes provenienti dalle strutture di
              governo, dal mondo della consulenza o dal settore privato.
            </Typography>
            <Link to="/docenti">
              Scopri gli analisti e i protagonisti della Scuola…
            </Link>
          </Grid>
        </Grid>

        {/* <Grid container spacing={3} className="flip-cards-container">
          {docents.map((docent) => {
            return (
              <Grid
                item
                key={docent.firstname}
                md={4}
                sm={6}
                xs={12}
                className="flip-card"
              >
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <img
                      src={docent.image}
                      className={classes.image}
                      title="Image title"
                    />
                    <Typography variant="h5" className="authors-name">
                      {docent.firstname}
                    </Typography>
                    <Typography variant="h5" className="authors-name">
                      {docent.lastname}
                    </Typography>
                    <Typography gutterBottom className="card-text card-title">
                      {docent.auth}
                    </Typography>
                  </div>
                  <div className={`flip-card-back ${classes.cardContent}`}>
                    <Typography className="card-text">
                      {docent.description}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid> */}
      </div>
    </div>
  );
};

export default CardSection;
