import React from "react";
import {
  Typography,
  makeStyles,
  Divider,
  Grid,
  Button,
} from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
}));

const EnrolledSection = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          LE CHIAVI DEL MONDO, COME USARE LA GEOPOLITICA
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Il corso della Scuola di Limes fornisce ai partecipanti le chiavi
              per interpretare il mondo. La geopolitica serve a cogliere le
              forze profonde che muovono la storia e l'attualità. Attraverso le
              lezioni dei nostri analisti e dei nostri testimoni ci proponiamo
              di trasmettere un metodo per capire i conflitti che scorrono sotto
              la pelle del pianeta. E per formare decisori che sappiano
              orientarsi secondo l'interesse nazionale italiano.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EnrolledSection;
