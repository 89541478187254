import React from "react"
import { Typography, makeStyles, Grid } from "@material-ui/core";
import ReactPlayer from "react-player";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import image4 from "../../images/image4.png";


const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: "100%",
        height: "auto",
    },

    blog: {
        marginTop: 32,
        textAlign: "center",
        padding: theme.spacing(6, 12.5),
        backgroundColor: "#ccd8ee",
        borderTop: "5px solid #00406c",
        borderBottom: "5px solid #00406c",
        "& h4": {
            marginBottom: 8,
        },
        "& h6": {
            marginBottom: 16,
        },

        [theme.breakpoints.down("md")]: {
            paddingLeft: 60,
            paddingRight: 60,
        },
        [theme.breakpoints.down("sm")]: {
            paddingLeft: 40,
            paddingRight: 40,
        },
    },
    auth: {
        color: "#3c3c3b",
    },
    video: {
        marginBottom: 30,
    },
}));

const Video = () => {

    const classes = useStyles();
    return (
        <Grid container spacing={3} className="media centered-video">
            <Grid item md={6} sm={6} xs={12}>
                <div className="player-wrapper">
                    <ReactPlayer
                        url={`https://player.vimeo.com/video/766797654?h=5e3423d8b6&title=0&byline=0&portrait=0`}
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls={true}
                        light={image1}
                        playing
                    />
                </div>
                <Typography variant="h5" color="initial" className="ilmond-title">
                    Nell'anticamera del potere
                </Typography>
                <Typography className={`${classes.text}`}>
                    Lucio Caracciolo e il Generale Giuseppe Cucchi sul ruolo del pre-consiglio in Italia.
                </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <div className="player-wrapper">
                    <ReactPlayer
                        url={`https://player.vimeo.com/video/766796465?h=fd80eaf797&title=0&byline=0&portrait=0`}
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls={true}
                        light={image2}
                        playing
                    />
                </div>
                <Typography variant="h5" color="initial" className="ilmond-title">
                    Fare geopolitica
                </Typography>
                <Typography className={`${classes.text}`}>
                    Come scrivere un’analisi geopolitica.
                </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <div className="player-wrapper">
                    <ReactPlayer
                        url={`https://player.vimeo.com/video/766813128?h=c498c079bd&title=0&byline=0&portrait=0`}
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls={true}
                        light={image3}
                        playing
                    />
                </div>
                <Typography variant="h5" color="initial" className="ilmond-title">
                    I codici interpretativi
                </Typography>
                <Typography className={`${classes.text}`}>
                    Il direttore Lucio Caracciolo dibatte lo scontro Usa-Cina con il sinologo Francesco Sisci.
                </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
                <div className="player-wrapper">
                    <ReactPlayer
                        url={`https://player.vimeo.com/video/766794353?h=8f60a6efbb&title=0&byline=0&portrait=0`}
                        className="react-player"
                        width="100%"
                        height="100%"
                        controls={true}
                        light={image4}
                        playing
                    />
                </div>
                <Typography variant="h5" color="initial" className="ilmond-title">
                    Come si negozia
                </Typography>
                <Typography className={`${classes.text}`}>
                    Perchè la lingua è potere in un negoziato geopolitico.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Video;