import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import coverImage1 from "../../images/HomePage.jpg";
import coverImage3 from "../../images/Map3.png";
import coverImage4 from "../../images/Map4.png";
import coverImage2 from "../../images/home3.jpg";
import coverImage5 from "../../images/Map5.jpg";
import coverImage6 from "../../images/Map6.jpg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cover: {
    maxWidth: "100%",
    height: "auto",
    width: "100%",
  },
  carousel: {
    marginBottom: 60,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 58,
    },
  },
}));

const CoverImage = () => {
  const classes = useStyles();
  const settings = {
    dots: true,
    autoplaySpeed: 10000,
    infinite: true,
    arrows: false,
    speed: 1500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.carousel}>
      <Slider {...settings}>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage1} alt="home-page-slider-images" />

        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage2} alt="home-page-slider-images" />

        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage3} alt="home-page-slider-images" />
        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage4} alt="home-page-slider-images" />
        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage5} alt="home-page-slider-images" />
        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage6} alt="home-page-slider-images" />
        </div>
      </Slider>
      <div className="banner-overlay">
        <p color="initial" className="banner-text-primary">
          LA GRANDE SCUOLA DI GEOPOLITICA E DI GOVERNO FIRMATA LIMES
        </p>
      </div>

    </div>
  );
};

export default CoverImage;
