import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Table from "./Table.jsx";
import { Grid } from "@material-ui/core";
import COOKIE from "./COOKIEPOLICY.jsx";
import SERVIZIO from "./SERVIZIO.jsx";
import Candidarure from "./Candidature.jsx";
import Contatto from "./Contatto.jsx";
import Brochure from "./Brochure.jsx";
import { Switch, Route, Link, BrowserRouter, NavLink } from "react-router-dom";
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "auto",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      flexDirection: "column",
    },
  },
  tabs: {
    minWidth: "40%",
    "& a": {
      minWidth: "100%",
      fontWeight: "normal",
      fontWeight: "domaineSanTextRegular",
      opacity: 1,
      "& >span": {
        justifyContent: "left",
        flexDirection: "initial",
        fontWeight: `"domaineSanTextRegular"`,
        fontSize: "0.975rem",
      },
    },
  },
  left: {
    height: "51vh",
    overflowY: "auto",
    marginLeft: 15,
    marginTop: -40,

    "& h1": {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 56,
      marginTop: 0,
      height: "auto",
    },
  },
  link: {
    color: "#ef9100",
  },
  privicy: {
    display: "block",
  },
  title: {
    textAlign: "center",
    marginTop: "15px !important",
  },
}));

export default function VerticalTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <BrowserRouter>
        <Route
          path="/"
          render={({ location }) => (
            <React.Fragment>
              <Tabs
                value={location.pathname}
                orientation="vertical"
                variant="scrollable"
                onChange={handleChange}
                aria-label="Vertical tabs example"
                className={classes.tabs}
                id="privicy_tab"
                selectionFollowsFocus={true}
              >
                <Tab
                  label="Informativa e cookie Policy"
                  component={NavLink}
                  to="/paginadocumenti"
                />
                <Tab
                  label="Condizioni del servizio"
                  component={NavLink}
                  to="/condizionidelservizio"
                />

                <Tab
                  label="Privacy candidature"
                  component={NavLink}
                  to="/candidature"
                />
                <Tab
                  label="Privacy contatti"
                  component={NavLink}
                  to="/contatto"
                />
                <Tab
                  label="Privacy brochure"
                  component={NavLink}
                  to="/brochure"
                />
              </Tabs>
              <Switch>
                <div className={classes.left}>
                  <Route path="/paginadocumenti" render={() => <COOKIE />} />
                  <Route
                    path="/condizionidelservizio"
                    render={() => <SERVIZIO />}
                  />
                  <Route path="/candidature" render={() => <Candidarure />} />
                  <Route path="/contatto" render={() => <Contatto />} />
                  <Route path="/brochure" render={() => <Brochure />} />
                </div>
              </Switch>
            </React.Fragment>
          )}
        />
      </BrowserRouter>
    </div>
  );
}
