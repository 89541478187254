import React from "react";
import NOSTRI_DOCENTISection from "../../components/Docenti/NOSTRI_DOCENTISection.jsx";
import TUTTI_I_DOCENTISection from "../../components/Docenti/TUTTI_I_DOCENTISection.jsx";
import CoverImage from "../../components/Docenti/CoverImage.jsx";

const Docenti = () => {
  return (
    <div>
      <CoverImage />
      <NOSTRI_DOCENTISection />
      <TUTTI_I_DOCENTISection />
    </div>
  );
};

export default Docenti;
