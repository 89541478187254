import React from "react";
import { Typography, makeStyles, Divider, Grid } from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";

import maincolors from "../Colors/Color";
const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  grow: {
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
    },
    "& > div": {
      flexGrow: 1,
    },

    "& > div > div": {
      marginRight: 20,

      [theme.breakpoints.down("sm")]: {
        height: "auto",
        marginBottom: 16,
        marginRight: 0,
      },
      "& p": {
        padding: "10px 20px",
      },
      "& a": {
        display: "block",
        paddingTop: 16,
        color: maincolors.orange,
      },
      "& h5": {
        padding: "10px",
        textAlign: "center",
        textTransform: "uppercase",
      },
      "& hr": {
        height: 5,
        margin: 0,
        background: "#fff",
      },
    },

    "& > div:nth-child(1) > div": {
      backgroundColor: maincolors.blue,
      color: maincolors.white,
    },
    "& >div:nth-child(2) > div": {
      backgroundColor: maincolors.middlebluecard,
      color: maincolors.white,
    },
    "& > div:nth-child(3) > div": {
      backgroundColor: maincolors.darkbluecard,
      color: maincolors.white,
      marginRight: 0,
    },
  },
  list: {
    "& li": {
      display: "list-item",
      listStyle: "disc",
      marginLeft: 20,
      paddingBottom: 0,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  item: {
    display: "flex",
    width: "100%",
  },
  text: {
    paddingTop: "1rem",
  },
  Link: {
    marginTop: 16,
    display: "block",
    color: maincolors.orange,
  },
}));
const MondoBlog = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          IL CORSO: LE CHIAVI DEL MONDO, COME USARE LA GEOPOLITICA
        </Typography>
        <Divider />
        <div className={classes.text}>
          <Typography>
            Il corso ha come obiettivo trasmettere gli strumenti dell’analisi geopolitica. Si divide in due parti, una dedicata ai pilastri della disciplina e una all’applicazione pratica dello strumentario nell’analizzare le crisi che determinano il posto del nostro paese nel mondo.
          </Typography>
          <a className={classes.Link} href="/icorsi">
            Scopri il corso…
          </a>
        </div>

        <Grid container className={classes.grow}>
          <Grid item md={4} className={classes.item}>
            <div className="mondo_second">
              <Typography variant="h5" className="table-title" color="initial">
                Frequenza
              </Typography>
              <Divider />
              <Typography className="mond-text">
                120 ore, aprile-luglio e settembre-dicembre, tre fine settimana al mese, lezioni il venerdì h15-18 e il sabato h10-13. Le lezioni si svolgono su piattaforma digitale con una partecipazione in presenza a rotazione.
              </Typography>
            </div>
          </Grid>

          <Grid item md={4} className={classes.item}>
            <div className="mondo_third">
              <Typography variant="h5" className="table-title" color="initial">
                Temi principali
              </Typography>
              <Divider />
              <Typography className="mond-text">
                Il fattore umano. Le principali crisi del pianeta. I conflitti tra potenze. Le dimensioni della competizione. Gli usi della storia. Lo sguardo italiano sul mondo. Cartografia geopolitica. Geopolitica economica e finanziaria. Codici interpretativi e negoziali.
              </Typography>
            </div>
          </Grid>
          <Grid item md={4} className={classes.item}>
            <div className="mondo_forth">
              <Typography variant="h5" className="table-title" color="initial">
                Opportunità di lavoro
              </Typography>
              <Divider />
              <Typography className="mond-text">
                I migliori talenti fra i partecipanti iuniores potranno accedere a una selezionata rete di aziende partner e di istituzioni per svolgere periodi di tirocinio da concordare.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default MondoBlog;
