import React from "react";
import { makeStyles, Grid, Typography, CardMedia, Link } from "@material-ui/core";
import maincolors from "../Colors/Color.js";
import image from "../../images/contatti1.jpg";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    marginBottom: theme.spacing(6),
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
  col: {
    marginTop: 16,
    marginBottom: 16,
  },
  textTitle: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  wording: {
    padding: "81px 112px",
    "& h1": {
      paddingBottom: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 40,
      padding: 0,
    },
  },
}));

const ContattiSection = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper-conttati">
      <Grid container spacing={(0, 2)}>
        <Grid item md={6} xs={12} className="image_wrapper">
          <CardMedia
            title="cardMedia"
            image={image}
            className="map_image hide"
          />
        </Grid>
        <Grid item md={6} sm={12} className="contact_wrapper">
          <div className={classes.wording}>
            <Typography variant="h1">CONTATTACI</Typography>
            {/* <Typography className={classes.text}>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </Typography> */}
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  variant="h5"
                  color="initial"
                  className="ilmond-title"
                >
                  Segreteria
                </Typography>

                <div className={classes.col}>
                  <Typography>Email : info@scuoladilimes.it</Typography>
                </div>
                <div className={classes.col}>
                  <Typography>Telefono : +39 06 49822803 </Typography>
                </div>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  variant="h5"
                  color="initial"
                  className="ilmond-title"
                >
                  Sede
                </Typography>

                <div className={classes.col}>
                  <Typography>
                    Via Cristoforo Colombo 90 <br />
                    00147 Roma RM
                  </Typography>
                  <div className="social-media-icons contact">
                    <Link href="https://www.linkedin.com/school/scuola-limes-geopolitica/" target="_blank">
                      <LinkedInIcon />
                    </Link>
                    <Link href="https://www.instagram.com/scuoladilimes/" target="_blank">
                      <InstagramIcon />
                    </Link>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ContattiSection;
