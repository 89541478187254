import React from "react";
import { Container } from "@material-ui/core";
import BackToTop from "../../components/Layout/BackToTop.jsx";
import CoverImage from "../../components/Layout/CoverImage.jsx";
import ContattiSection from "../../components/Contatti/ContattiSection.jsx";
import ContactForm from "../../components/Contatti/ContactForm.jsx";

const Contatti = () => {
  return (
    <div>
      <ContattiSection />
      <ContactForm />
      <BackToTop />
    </div>
  );
};

export default Contatti;
