import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  Link,
} from "@material-ui/core";

import Rectangle from "../Layout/Rectangle.jsx";
import DocintiImages from "../../helpers/DocintiImages";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      color: "#ef9100",
      marginBottom: 16,
      paddingLeft: 8,
      textDecoration: "underline",
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 400,
  },
  root: {
    boxShadow: "none",
    borderRadius: 0,
  },
  cardContent: {
    padding: "16px 0",
    paddingBottom: "0 !important",
  },
  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 112,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
  container: {
    marginTop: "1rem",
  },
}));



const docents = [
  {
    firstname: "Lucio",
    lastname: "Caracciolo",

    description:
      "Direttore di Limes, che ha fondato nel 1993, editorialista per la Repubblica e la Stampa, insegna Studi strategici alla LUISS Guido Carli e Geopolitica all’Università Vita e Salute – San Raffaele.",
    auth: "Direttore",
    image: DocintiImages["Lucio-Caracciolo.jpg"]
  },

  {
    firstname: "Laura",
    lastname: "Canali",

    description:
      "Esperta in cartografia geopolitica, responsabile della cartografia e delle copertine di Limes. Ha esposto i suoi lavori, tra gli altri, alla Biennale di Venezia 2012, al MAXXI B.A.S.E. e al Palazzo Ducale di Genova.",
    auth: "Cartografa",
    image: DocintiImages["Laura-Canali.jpg"]
  },
  {
    firstname: "Federico",
    lastname: "Petroni",

    description:
      "Consigliere redazionale di Limes, responsabile del Limes Club Bologna e co-fondatore di iMerica.",
    auth: "Coordinatore didattico",
    image: DocintiImages["Federico-Petroni.jpg"]
  },
  {
    firstname: "Giorgio",
    lastname: "Cuscito",
    description:
      "Consigliere redazionale di Limes, analista, studioso di geopolitica della Cina e dell’Indo-Pacifico. Curatore del Bollettino Imperiale su limesonline.com",
    auth: "Coordinatore club alumni e relazioni esterne",
    image: DocintiImages["Giorgio-Cuscito.jpg"]
  },
  {
    firstname: "Orietta",
    lastname: "Moscatelli",
    description: `Esperta di Russia e spazio ex Sovietico, negli anni Novanta ha vissuto e lavorato a Mosca, dove torna regolarmente. Collabora con Limes dal 2004. Dopo Mosca, Londra e Lione ora vive a Roma. È caporedattore Esteri dell'agenzia Askanews. Co-autrice dei libri: "Cecenia" e "Ucraina, anatomia di un terremoto".`,
    auth: "Responsabile esercitazioni",
    image: DocintiImages["Orietta-Moscatelli.jpg"]
  },
];
const ANALISTIdocents = [
  {
    id: "5",
    firstname: "Rosario",
    lastname: "Aitala",

    image: DocintiImages["aitala.jpg"]
  },
  {
    id: "6",
    firstname: "Giuseppe",
    lastname: "Cucchi",
    image: DocintiImages["Cucchi.jpg"]
  },
  {
    id: "7",
    firstname: "Carlo",
    lastname: "Jean",
    image: DocintiImages["jean.jpg"]
  },
  {
    id: "8",
    firstname: "Fabio",
    lastname: "Mini",
    image: DocintiImages["mini.jpg"]
  },
  {
    id: "9",
    firstname: "Massimo",
    lastname: "Nicolazzi",
    image: DocintiImages["nicolazzi.jpg"]
  },
  {
    id: "10",
    firstname: "Paolo",
    lastname: "Peluffo",
    image: DocintiImages["peluffo.jpg"]
  },
  {
    id: "11",
    firstname: "Francesco",
    lastname: "Sisci",
    image: DocintiImages["sisci.jpg"]
  },
  {
    id: "12",
    firstname: "George",
    lastname: "Friedman (Usa)",
    image: DocintiImages["friedmann.jpg"]
  },
  {
    id: "13",
    firstname: "Jacob",
    lastname: "Shapiro (Usa)",
    image: DocintiImages["shapiro.jpg"]
  },
  {
    id: "14",
    firstname: "Shen",
    lastname: "Dingli (Cina)",
    image: DocintiImages["shen.jpg"]
  },
  {
    id: "15",
    firstname: "You",
    lastname: "Ji (Cina)",
    image: DocintiImages["youji.jpg"]
  },
  {
    id: "16",
    firstname: "Sergej",
    lastname: "Karaganov (Russia)",
    image: DocintiImages["karaganov.jpg"]
  },
  {
    id: "17",
    firstname: "Dmitrij",
    lastname: "Trenin (Russia)",
    image: DocintiImages["trenin.jpg"]
  },
  {
    id: "18",
    firstname: "Heribert",
    lastname: "Dieter (Germania)",
    image: DocintiImages["dieter.jpg"]
  },
  {
    id: "19",
    firstname: "Pierre-Emmanuel",
    lastname: "Thomann (Francia)",
    image: DocintiImages["thomann.jpg"]
  },
  {
    id: "20",
    firstname: "Abdolrasool",
    lastname: "Divsallar (Iran)",
    image: DocintiImages["divsalla.jpg"]
  },
  {
    id: "21",
    firstname: "Franco",
    lastname: "Bernabè",
    image: DocintiImages["Bernabè.jpg"]
  },
];

const sorteddocents = ANALISTIdocents.sort(function (a, b) {
  return a.lastname.localeCompare(b.lastname);
});

const DocentsSection = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Rectangle />
      <div className={classes.block}>
        <div className="section-wrapper">
          <Typography variant="h1" color="initial">
            LA DIREZIONE DELLA SCUOLA
          </Typography>

          <Divider />

          <Grid container spacing={3} className={classes.container}>
            {docents.map((docent) => {
              return (
                <Grid
                  item
                  key={docent.firstname}
                  md={4}
                  sm={6}
                  xs={12}
                  className="flip-card"
                >
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <img
                        src={docent.image}
                        className={classes.image}
                        title="Image title"
                      />
                      <Typography variant="h5" className="authors-name">
                        {docent.firstname}
                      </Typography>
                      <Typography variant="h5" className="authors-name">
                        {docent.lastname}
                      </Typography>
                      <Typography gutterBottom className="card-text card-title">
                        {docent.auth}
                      </Typography>
                    </div>
                    <div className={`flip-card-back ${classes.cardContent}`}>
                      <Typography className="card-text">
                        {docent.description}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
        <Link href={"/docenti"}>Scopri tutti i docenti</Link>
      </div>
    </React.Fragment>
  );
};

export default DocentsSection;
