import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import Video from "../../Videos/Corso.mp4";
import image from "../../images/Limes-video-cover.png";

import ReactPlayer from "react-player";
const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
    height: "auto",
  },

  blog: {
    marginTop: 32,
    textAlign: "center",
    padding: theme.spacing(6, 12.5),
    backgroundColor: "#ccd8ee",
    borderTop: "5px solid #00406c",
    borderBottom: "5px solid #00406c",
    display: "none",
    "& h4": {
      marginBottom: 8,
    },
    "& h6": {
      marginBottom: 16,
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  auth: {
    color: "#3c3c3b",
  },
  video: {
    marginBottom: 30,
  },
}));

const Block = () => {
  const classes = useStyles();
  return (
    <div className={classes.blog}>
      <Grid container spacing={3}>
        <Grid item md={12}>
          <Typography variant="h5" color="initial" className="video-title">
            Le chiavi del mondo, gli strumenti di analisi
          </Typography>
          <Typography
            variant="h6"
            color="initial"
            className={`auth-title ${classes.auth}`}
          >
            di Lucio Caracciolo
          </Typography>
          <Grid container className={classes.video}>
            <Grid item xs={12}>
              <div className="player-wrapper-iframe">
                <ReactPlayer
                   url={`https://player.vimeo.com/video/767209429?h=4da5cd2a42&title=0&byline=0&portrait=0`}
                  light={image}
                  className="react-player"
                  playing
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </Grid>
          </Grid>
          <Typography>
            Il direttore Lucio Caracciolo presenta il corso della Scuola di Limes 2023
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Block;
