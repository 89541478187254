import React, { useEffect } from "react";
import { Typography, makeStyles, Divider, Grid } from "@material-ui/core";
import Block from "./Block.jsx";
import Videos from './Videos.jsx';

const useStyles = makeStyles((theme) => ({
  block: {
    textAlign: "center",
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },

  blog: {
    padding: theme.spacing(6, 12.5),
    backgroundColor: "#eff0f1",
    "& h4": {
      marginBottom: 8,
    },
    "& h6": {
      marginBottom: 16,
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  auth: {
    color: "#3c3c3b",
  },
  div: {
    marginTop: 50,
    marginBottom: 112,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
}));

const TitoloBlog = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://player.vimeo.com/api/player.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          Geopolitica al servizio dell’Italia
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              La Scuola di Limes, giunta alla V edizione, nasce nel 2021 per contribuire alla formazione della classe dirigente di oggi e di domani. Stiamo vivendo un cambio di paradigma: senza strumenti per capire le crisi che ci circondano, non è possibile prendere decisioni per proteggere e promuovere gli interessi nazionali. La Scuola trasmette un sapere applicato, da spendere nelle istituzioni, nell'amministrazione, in aziende pubbliche e private.
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.div}>
        
        <Typography variant="h1" color="initial">
        Dentro la Scuola di Limes
        </Typography>
        <div
      style={{ padding: '56.25% 0 0 0', position: 'relative' }}
      dangerouslySetInnerHTML={{
        __html: `
          <iframe
            src="https://player.vimeo.com/video/900110988?h=98e891297f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            style="position:absolute;top:0;left:0;width:100%;height:100%;margin-top:20px;"
            title="Scuola di Limes 2023a"
          ></iframe>
        `,
      }}
    />
        </div>
      </div>
      <Block />
         
    </div>
  );
};

export default TitoloBlog;
