import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Container, Link } from "@material-ui/core";
import maincolors from "../Colors/Color";
import { useForm, Controller } from "react-hook-form";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import DialogTitle from "@material-ui/core/DialogTitle";
import {
  Button,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  banner: {
    backgroundColor: maincolors.orange,
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    transition:
      "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    padding: "8px 16px",
    borderRadius: 8,
    position: "absolute",
    top: 50,
    display: "flex",
    right: 30,
    [theme.breakpoints.down("xs")]: {
      position: "relative",
      width: "100%",
      right: 0,
      top: 0,
      height: "auto",
      borderRadius: "0",
      display: "block",
      textAlign: "center",
      padding: "8px 0px",
    },
    "& span": {
      alignSelf: "center",
      textAlign: "center",
      textTransform: "uppercase",
      lineHeight: "1.2",
      color: "#ffff",
      [theme.breakpoints.down("md")]: {
        letterSpacing: 0.29,
      },
      [theme.breakpoints.down("sm")]: {
        letterSpacing: 0.29,
      },
      [theme.breakpoints.down("xs")]: {
        paddingTop: 8,
        paddingBottom: 8,
        textAlign: "center",
        width: "auto",
        letterSpacing: 0.29,
        textAlign: "center",
        color: "#ffff",
        margin: 0,
      },
    },
    "& a": {
      margin: "auto",
    },
    "& hover": {
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    },
    "& active": {
      boxShadow:
        "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)",
    },
  },
  container: {
    position: "relative",
  },
  link: {
    textAlign: "center",
    "&:hover": {
      textDecoration: "none",
    },
  },
  meinList: {
    backgroundColor: "#ccd8ee",
    color: "#00416b",
    boxShadow: "none",
    padding: theme.spacing(2),
    marginTop: "1.5rem",
    borderTop: "1px solid #00416b",
    borderRadius: 0,
  },

  buttonSubmit: {
    backgroundColor: "#00416b",
    color: "#fff",
    borderRadius: 0,
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "&:hover": {
      color: "#fff",
      backgroundColor: "#00416b",
    },
  },
  listItem: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
    paddingBottom: "1rem",
    borderBottom: "1px solid #00416b",
    marginLeft: -15,
    marginRight: -15,
    paddingLeft: 15,
    paddingRight: 15,
  },
  text: {
    textAlign: "start",
    marginLeft: theme.spacing(2),
  },
  link: {
    "&:hover": {
      textDecoration: "none",
    },
  },
  label: {
    paddingBottom: 10,
  },
  button: {
    justifyContent: "start",
    paddingLeft: 22,
    paddingBottom: 30,
  },
  dialog: {
    "& .MuiDialog-paper": {
      backgroundColor: "#ccd8ee",
      borderRadius: 0,
    },
    "& input": {
      backgroundColor: "#fff",
    },
  },
  terms: {
    paddingLeft: 22,
    paddingRight: 30,
  },
  req: {
    paddingLeft: 22,
    paddingRight: 30,
  },
  closeicon: {
    float: "right",
    paddingRight: theme.spacing(2),
    paddingTop: 16,
    cursor: "pointer",
    color: maincolors.darkblue,
    fontSize: "2rem",
  },
  checkboxLabel: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 0,
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: 36,
    },
    "& a": {
      color: "#00416b",
    },
  },
}));
const NAME_REGEXP = /^[^\d"@!?/|<>]+$/i;
const SecondaryBanner = () => {
  const { register, errors, handleSubmit, control } = useForm();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");
  const [showForm, setShowForm] = React.useState(true);
  const initialMessage =
    "Inserisci i tuoi dati per ricevere la brochure via email";
  // Message to display instead of the form
  const [message, setMessage] = React.useState(initialMessage);
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
    setShowForm(true);
    setMessage(initialMessage);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const doSubmitForm = (data) => {
    setShowForm(false);
    fetch("/submit_brochure_request", {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams(data),
    })
      .then((res) => {
        if (res.status < 400) {
          setMessage(`Ti abbiamo inviato la brochure per email`);
        } else {
          console.error(`A server error occurred: ${res.status}`);
          setMessage("Siamo spiacenti, si è verificato un errore");
        }
      })
      .catch((err) => {
        console.error(err);
        setMessage(`Si è verificato un errore. Si prega di riprovare`);
        setShowForm(true);
      });
  };

  return (
    <Container
      maxWidth="lg"
      disableGutters={true}
      className={classes.container}
    >
      <div className={classes.banner} id="bannerButton">
        <Link href="/icorsi#form-title" className={classes.link} id="anchor">
          <span className="banner-text-secondery" color="initial">
          SONO APERTE LE ISCRIZIONI AL CORSO
           <br />
           <span className="banner-text-secondery bolder-banner-text"> LE CHIAVI DEL MONDO 2025</span>
          <br />
          <span>PER ISCRIVERTI COMPILA IL FORM</span>.
          </span>
        </Link>
      </div>  
      {/* <div className={classes.banner} id="bannerButton">
        <Link className={classes.link} id="anchor">
          <span className="banner-text-secondery" color="initial">
          DAL 7 OTTOBRE SARA’ DISPONIBILE IL CORSO ON DEMAND DELLA SCUOLA DI LIMES.
           <br />
           PER LE ISCRIZIONI, INVIARE UN'EMAIL A <a href="mailto:info@scuoladilimes.it">INFO@SCUOLADILIMES.IT</a>.
          </span>
        </Link>
      </div> */}

      <Dialog
          className={classes.dialog}
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <div>
            <CloseIcon className={classes.closeicon} onClick={handleClose} />
            <DialogTitle id="form-dialog-title">{message}</DialogTitle>
          </div>
          {showForm ? (
            getForm({
              onSubmit: handleSubmit(doSubmitForm),
              classes,
              control,
              errors,
              value,
              handleChange,
              register,
            })
          ) : (
            <React.Fragment />
          )}
        </Dialog>
    </Container>
  );
};

function getForm({
  onSubmit,
  classes,
  control,
  errors,
  value,
  handleChange,
  register,
}) {
  return (
    <form
      id="form-dialog"
      action="/"
      method="POST"
      encType="multipart/form-data"
      onSubmit={onSubmit}
    >
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <label className={classes.label}>Nome *</label>
            <TextField
              fullWidth
              className={classes.input}
              type="text"
              name="firstname"
              required={true}
              variant="outlined"
              placeholder="Inserisci"
              autoFocus
              InputLabelProps={{ shrink: false }}
              inputRef={register({
                pattern: NAME_REGEXP,
              })}
            />
            <span className={classes.validation}>
              {errors.firstname &&
                'Il nome non può contenere numeri o " @  ! ? / | < >'}
            </span>
          </Grid>
          <Grid item sm={6} xs={12}>
            <label className={classes.label}>Cognome *</label>
            <TextField
              fullWidth
              className={classes.input}
              type="text"
              name="lastname"
              required={true}
              variant="outlined"
              placeholder="Inserisci"
              inputRef={register({
                pattern: NAME_REGEXP,
              })}
            />
            <span className={classes.validation}>
              {errors.lastname &&
                'Il cognome non può contenere numeri o " @  ! ? / | < >'}
            </span>
          </Grid>

          <Grid item sm={12} xs={12}>
            <label className={classes.label}>Email *</label>
            <TextField
              fullWidth
              required={true}
              type="email"
              name="email"
              className={classes.input}
              variant="outlined"
              inputRef={register()}
              placeholder="Inserisci"
              InputLabelProps={{ shrink: false }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Typography className={classes.req}>* Campi obbligatori</Typography>
      <div className={`terms-forms ${classes.terms}`}>
        <label>
          <span>
            Proseguendo, dichiari di aver letto e compreso{" "}
            <a className={classes.labellink} href="./brochure">
              l'informativa sulla privacy
            </a>
          </span>
        </label>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              required={false}
              name="GEDI_accepted"
              value="GEDI_accepted"
              color="primary"
              inputRef={register()}
            />
          }
          label={
            <span>
              Cliccando qui presti il consenso a che GEDI Gruppo Editoriale
              S.p.A. e le sue società controllate trattino i tuoi dati personali
              per l’invio di comunicazioni commerciali inerenti alle attività
              della Scuola di Limes e ai propri prodotti e servizi.
            </span>
          }
        />
      </div>
      <DialogActions className={classes.button}>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.buttonSubmit}
        >
          Invia
        </Button>
      </DialogActions>
    </form>
  );
}

export default SecondaryBanner;
