import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import maincolors from "../Colors/Color.js";
import Rectangle from "../Layout/Rectangle.jsx";
import Brochure from "./brochure_alumni-limes_24.pdf";

const useStyles = makeStyles((theme) => ({
  block: {
    marginTop:60,
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    paddingTop: "1rem",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
}));

const Partnership = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
        PARTNERSHIP
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
            Desideriamo informarvi che Limes ha concluso degli accordi di partnership con Fondazione Imago Mundi, Arper e CentroMarca Banca di Credito cooperativo di Treviso e Venezia per l’erogazione in favore della Scuola di un contributo destinato al finanziamento di tre corsi per l’anno 2024, da destinarsi in favore degli studenti più meritevoli.

            </Typography>
            <Typography className={classes.text}> 
            La Direzione della Scuola valuterà e selezionerà a propria esclusiva discrezione tre studenti tra quelli già iscritti al corso, sulla base del Curriculum vitae già trasmesso in fase di iscrizione.
            </Typography>

            <Typography className={classes.text}>
            I nomi dei 3 studenti scelti verranno comunicati agli interessati entro il 12 aprile 2024  per gestire gli aspetti economici ed amministrativi, inclusi rimborsi e compensazioni economiche.
            </Typography>
            <Typography className={classes.text}>
            A Fondazione Imago Mundi, ad Arper e a CentroMarca Banca di Credito cooperativo di Treviso e Venezia va il ringraziamento di Limes per l’interesse mostrato verso le attività della Scuola, per la fiducia dimostrata nei confronti di Direzione e corpo docente, ed in generale per il sostegno all’ambizioso obiettivo di contribuire alla formazione della classe dirigente del nostro Paese.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Partnership;
