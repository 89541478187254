import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";

import maincolors from "../Colors/Color.js";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
  item: {
    display: "flex",

    width: "100%",
  },
  grow: {
    paddingLeft: 100,
    paddingRight: 100,
    marginTop: 32,
    marginBottom: 32,
    [theme.breakpoints.down("md")]: {
      marginTop: 16,
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& > div": {
      flexGrow: 1,
    },

    "& > div > div": {
      marginRight: 20,
      "& h5": {
        padding: "10px",
        textAlign: "center",
        textTransform: "uppercase",
      },
      "& hr": {
        height: 5,
        margin: 0,
        background: "#fff",
      },
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        marginBottom: 16,
        marginRight: 0,
      },
      "& p": {
        padding: "10px 20px",
      },
      "& a": {
        display: "block",
        paddingTop: 16,
        color: maincolors.orange,
      },
    },
    "& > div:nth-child(1) > div": {
      backgroundColor: "#42aaea",
      color: maincolors.white,
    },
    "& > div:nth-child(2) > div": {
      backgroundColor: maincolors.blue,
      color: maincolors.white,
    },
    "& >div:nth-child(3) > div": {
      backgroundColor: maincolors.middlebluecard,
      color: maincolors.white,
    },
    "& > div:nth-child(4) > div": {
      backgroundColor: maincolors.darkbluecard,
      color: maincolors.white,
      marginRight: 0,
    },
  },
}));

const CosaCiDistingue = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          COSA CI DISTINGUE
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Non abbiamo allestito l’ennesima struttura para-universitaria, non
              intendiamo spiegare teorie astratte né diffondere ideologie. Non
              forniamo l'ennesimo sapere iperspecialistico, ma prepariamo a una
              visione geograficamente ampia e storicamente profonda. La Scuola
              fornisce le basi per penetrare come ragionano e decidono le
              principali potenze mondiali, per capire le loro strategie (e
              tattiche) nelle partite in corso. Trasmettiamo un modo alternativo
              di osservare il mondo. Sempre a partire dall’interesse nazionale
              italiano.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container className={classes.grow}>
        <Grid item md={3} className={classes.item}>
          <div className="mondo_first">
            <Typography variant="h5" className="table-title" color="initial">
              Qualità
              <br /> e originalità
            </Typography>
            <Divider />
            <Typography className="mond-text">
              In quasi trent’anni, con le pubblicazioni, i seminari, gli eventi,
              le consulenze e le attività di formazione, Limes si è costruita
              una solida reputazione in Italia e all’estero per la capacità di
              decifrare i conflitti e le competizioni mondiali.
            </Typography>
          </div>
        </Grid>
        <Grid item md={3} className={classes.item}>
          <div className="mondo_second">
            <Typography variant="h5" className="table-title" color="initial">
              La rete <br /> internazionale
            </Typography>
            <Divider />
            <Typography className="mond-text">
              Protagonisti della vita pubblica con ampia conoscenza e
              frequentazione degli apparati di Stato e di strutture private. Ad
              accomunarli, la consolidata esperienza e la profonda comprensione
              della gestione degli affari strategici, pubblici e privati.
            </Typography>
          </div>
        </Grid>

        <Grid item md={3} className={classes.item}>
          <div className="mondo_third">
            <Typography variant="h5" className="table-title" color="initial">
              L’approccio <br /> non accademico
            </Typography>
            <Divider />
            <Typography className="mond-text">
              La geopolitica non è scienza, non prescrive leggi. È una
              disciplina pratica, applicata, è prassi di governo. La nostra
              Scuola muove dal punto di vista italiano sul mondo che cambia. Le
              esercitazioni pratiche sono un elemento fondante perché servono a
              interpretare i ruoli degli attori geopolitici.
            </Typography>
          </div>
        </Grid>
        <Grid item md={3} className={classes.item}>
          <div className="mondo_forth">
            <Typography variant="h5" className="table-title" color="initial">
              Uno sguardo
              <br /> controcorrente
            </Typography>
            <Divider />
            <Typography className="mond-text">
              L’analisi dell’attualità è viziata da profondi fraintendimenti.
              Dal ruolo quasi onnipotente dei leader al primato delle
              interpretazioni economicistiche. Spostiamo l’occhio sul tempo
              lungo della storia e sull’analisi delle collettività e
              distinguiamo il durevole dall’effimero, la strategia dalla
              tattica, la continuità dal cambiamento.
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CosaCiDistingue;
