import React from "react";
import Documents from "../../components/Documents/Documents.jsx";

const privacyDocument = () => {
  return (
    <div>
      <Documents />
    </div>
  );
};

export default privacyDocument;
