const maincolors = {
  orange: "#ef9100",
  blue: "#009de1",
  darkblue: "#00406c",
  white: "#fff",
  black: "#000",
  lightblue: "#a9cdff",
  gray: "#cacaca",
  middlebluecard: "#0e72b8",
  darkbluecard: "#00588d",
};

export default maincolors;
