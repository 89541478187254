import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles({
  wrapper: {
    width: "100%",
    display: "block",
  },
});

function createData(Nome, Tipologia, Fornitore, Durata) {
  return { Nome, Tipologia, Fornitore, Durata };
}

const rows = [
  createData("sessionid", "tecnico", "GEDI Digital S.r.l.", "4 weeks"),
  createData("csrftoken", "tecnico", "GEDI Digital S.r.l.", "364 days"),
  createData(
    "openedx-language-preference",
    "tecnico",
    "GEDI Digital S.r.l.",
    "2 weeks"
  ),
  createData("edxloggedin", "tecnico", "GEDI Digital S.r.l.", "4 weeks"),
  createData("edx-user-info", "tecnico", "GEDI Digital S.r.l.", "4 weeks"),
  createData(
    "experiments_is_enterprise",
    "tecnico",
    "GEDI Digital S.r.l.",
    "1 year + 2 weeks"
  ),
  createData(
    "edx-jwt-cookie-header-payload",
    "tecnico",
    "GEDI Digital S.r.l.",
    "1 hour"
  ),
  createData(
    "dx-jwt-cookie-signature",
    "tecnico",
    "GEDI Digital S.r.l.",
    "1 hour"
  ),
];

export default function BasicTable() {
  const classes = useStyles();

  return (
    <Grid container justify={"center"} className={classes.wrapper}>
      <Grid item xs={12} md={10} style={{ padding: "8px" }}>
        <Paper style={{ overflowX: "auto" }}>
          <Table style={{ minWidth: "340px" }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <p className="strong">Nome</p>
                </TableCell>
                <TableCell align="right">
                  <p className="strong">Tipologia</p>
                </TableCell>
                <TableCell align="right">
                  <p className="strong">Fornitore</p>
                </TableCell>
                <TableCell align="right">
                  <p className="strong">Durata</p>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.Nome}>
                  <TableCell component="th" scope="row">
                    {row.Nome}
                  </TableCell>
                  <TableCell align="right">{row.Tipologia}</TableCell>
                  <TableCell align="right">{row.Fornitore}</TableCell>
                  <TableCell align="right">{row.Durata}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Grid>
    </Grid>
  );
}
