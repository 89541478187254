import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import coverImage from "../../images/Chi-siamo_red.jpg";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cover: {
    maxWidth: "100%",
    height: "50%",
    width: "100%",
  },
  carousel: {
    marginBottom: 60,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 58,
    },
  },
}));

const CoverImage = () => {
  const classes = useStyles();
  const settings = {
    dots: true,
    autoplaySpeed: 10000,
    infinite: true,
    arrows: false,
    speed: 1500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.carousel}>
      <Slider {...settings}>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage} alt="chi siamo" />
        </div>
      </Slider>
      <div className="banner-overlay">
        <p color="initial" className="banner-text-primary">
          UNA RETE ITALIANA E INTERNAZIONALE DI STUDIOSI E DI ATTORI DELLA
          GEOPOLITICA
        </p>
        ``{" "}
      </div>
    </div>
  );
};

export default CoverImage;
