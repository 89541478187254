import React from "react";
import { Typography, Grid, Divider, makeStyles } from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";
import VerticalTabs from "./VirticalTab.jsx";

const useStyles = makeStyles((theme) => ({
  div: {
    marginTop: 180,
  },
  block: {
    paddingLeft: 100,
    paddingRight: 80,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      /*   display: "block",
      paddingTop: 16,
      marginBottom: 16, */
      color: "#ef9100",
      "& svg": {
        float: "right",
      },
    },
  },
}));

const Condizionidelservizio = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <div className={"section-document"}>
        <Rectangle />
        <div className={`block ${classes.block}`}>
          <Typography variant="h1" color="initial">
            Documenti
          </Typography>

          <Divider />
          <VerticalTabs />
        </div>
      </div>
    </div>
  );
};

export default Condizionidelservizio;
