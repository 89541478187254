import React from "react";
import coverImage1 from "../../images/limes-homebanner.jpg";
import coverImage2 from "../../images/Caoslandia-Scuola.jpg";
import coverImage3 from "../../images/Pianeti.jpg";
import coverImage4 from "../../images/Virus.jpg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from "react-material-ui-carousel";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cover: {
    maxWidth: "100%",
    height: "auto",
    width: "100%",
  },
  carousel: {
    marginBottom: 60,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      marginTop: 58,
    },
  },
}));

const CoverImage = () => {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className={classes.carousel}>
      <Slider {...settings}>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage2} alt="place-holder" />
        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage3} alt="place-holder" />
        </div>
        <div className="cover-wrapper">
          <img className={classes.cover} src={coverImage4} alt="place-holder" />
        </div>
      </Slider>
      <p color="initial" className="banner-text-primary">
        La grande scuola di geopolitica e di governo firmata Limes
      </p>
    </div>
  );
};

export default CoverImage;
