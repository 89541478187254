import React from "react";
import { makeStyles, Divider, Grid, Typography } from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";
import ReadMore from "./Readmore.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      "&:hover": {
        textDecoration: "underline",
      },

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },

  content: {
    backgroundColor: "#CCD8EE",
    padding: 22,
    paddingTop: 0,
  },
  section: {
    marginTop: "1rem",
  },
  title: {
    backgroundColor: "#CCD8EE",
    padding: 22,
    paddingBottom: 0,
    height: 90,
    [theme.breakpoints.down("sm")]: {
      height: 180,
    },
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
}));
const TemiSection = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
        GLI ARGOMENTI PRINCIPALI
        </Typography>
        <Divider />
        <Grid container spacing={3} className={classes.section}>
          <Grid item md={6} sm={6}>
            <div className={classes.title} id="title">
              <Typography variant="h5" color="initial" className="ilmond-title">
                Il fattore umano in geopolitica
              </Typography>
            </div>
            <div className={classes.content}>
              <ReadMore>
                <div>
                  <Typography className={classes.text}>
                    Troppo spesso la potenza viene misurata in termini
                    quantitativi, trascurando la decisiva dimensione
                    qualitativa. Pil, commerci, barili di petrolio, numero di
                    missili o di navi da guerra e via dicendo non sono
                    sufficienti a restituirci la gerarchia delle potenze o a
                    spiegare conflitti e rivalità. Occorre studiare le
                    collettività, unità di analisi minima della geopolitica,
                    cioè che cosa le anima e cosa le spaventa, il loro rapporto
                    con la storia e con la violenza, il loro dinamismo,
                    ovviamente caso per caso. In questo modo si può sfuggire ai
                    fraintendimenti politologici ed economicistici, per esempio
                    l’esagerato ruolo normalmente attribuito ai leader o la
                    convinzione che ragioni pecuniarie spieghino il
                    comportamento delle potenze.
                  </Typography>
                </div>
              </ReadMore>
            </div>
          </Grid>
          <Grid item md={6} sm={6}>
            <div className={classes.title} id="title">
              <Typography variant="h5" color="initial" className="ilmond-title">
                La competizione tra potenze e altri attori formali e informali
              </Typography>
            </div>
            <div className={`collapse-text ${classes.content}`}>
              <ReadMore>
                <Typography className={classes.text}>
                  La geopolitica muove dal presupposto che la realtà che ci
                  circonda è competitiva, conflittuale. Essa dunque penetra le
                  paure più recondite di ciascuna collettività, che normalmente
                  hanno a che vedere con la sopravvivenza e danno forma a
                  necessità strategiche. Quindi studia come gli attori, statuali
                  e non, si misurano fra loro in spazi finiti e nel corso del
                  tempo storico a partire da questi imperativi, che ne informano
                  gli obiettivi. Non per forza ciò si manifesta in guerre
                  guerreggiate. Anzi il confine tra guerra e pace è sempre più
                  indistinguibile. Studiamo dunque il costante sforzo di un
                  soggetto geopolitico di ottenere una posizione, un’immagine,
                  risorse migliori dei propri nemici, dei propri rivali, pure
                  dei propri alleati. Cruciale è riconoscere la gerarchia delle
                  partite in corso. Nel nostro percorso esamineremo, in ordine
                  appunto gerarchico, le poste in gioco e le molteplici
                  dimensioni dello scontro tra Stati Uniti e Cina, lo stato
                  dell’impero americano, le radici e le prospettive delle
                  aspirazioni di Pechino, la lotta per la sopravvivenza (come
                  grande potenza) della Russia, il ritorno della Germania alle
                  questioni strategiche, l’irriducibile diversità e litigiosità
                  dell’Europa e il suo ancora inaggirabile valore strategico, la
                  profondità imperiale di Turchia e Iran e via dicendo. Sempre
                  tenendo fisso il punto di vista italiano. Allo scopo di
                  fornire strumenti per orientare il nostro interesse nazionale
                  in queste partite, ciascuna delle quali ci vede svolgere un
                  ruolo, spesso per nulla indifferente.
                </Typography>
              </ReadMore>
            </div>
          </Grid>
          <Grid item md={6} sm={6}>
            <div className={classes.title} id="title">
              <Typography variant="h5" color="initial" className="ilmond-title">
                Come si raccontano le potenze
              </Typography>
            </div>
            <div className={classes.content}>
              <ReadMore>
                <Typography className={classes.text}>
                  La storia è una formidabile arma strategica. Chi sa stare al
                  mondo attinge al proprio passato per spiegare il presente,
                  giustificare il proprio potere, legittimare obiettivi futuri,
                  incanalare le aspirazioni della collettività. Controllare il
                  passato serve a proiettarsi nel futuro. La storia è il
                  collante che tiene assieme la collettività. Per questo
                  dedichiamo grande attenzione alle pedagogie nazionali, a come
                  viene insegnata la storia sin dalle scuole d’infanzia, alla
                  mitopoietica strategica, perché permette di penetrare le
                  mentalità e le culture che informano la strategia delle
                  potenze.
                </Typography>
              </ReadMore>
            </div>
          </Grid>

          <Grid item md={6} sm={6}>
            <div className={classes.title} id="title">
              <Typography variant="h5" color="initial" className="ilmond-title">
                Cartografia geopolitica, ovvero carte come armi
              </Typography>
            </div>
            <div className={classes.content}>
              <ReadMore>
                <Typography className={classes.text}>
                  Attraverso lo studio di carte prodotte da diversi soggetti
                  geopolitici in contrasto o in conflitto per il controllo di
                  spazi terrestri, marittimi, aerei o d’altro genere, impariamo
                  a vedere come non esiste una rappresentazione neutra degli
                  spazi. Le carte sono sempre strumento di progetti, intenzioni,
                  rivendicazioni geopolitiche. Spesso si usano carte del passato
                  anche lontano per rivendicare spazi attuali: vedi le dispute
                  nel Mar Cinese Meridionale, epicentro dello scontro Cina-Usa,
                  o quelle fra Grecia, Turchia ed altri nel Mediterraneo
                  orientale, dove sono in ballo importanti giacimenti
                  energetici, eccetera. Su questo Limes dispone di una
                  cartografia unica prodotta da Laura Canali, spesso ripresa da
                  alcuni attori in diversi paesi, costruita in quasi trent’anni
                  di lavoro. Attraverso apposite tecnologie i partecipanti
                  potranno lavorare su queste carte, studiarle ed eventualmente
                  adattarle a esplicitare le rivendicazioni della parte che
                  rappresentano.
                </Typography>
              </ReadMore>
            </div>
          </Grid>
          <Grid item md={6} sm={6}>
            <div className={classes.title} id="title">
              <Typography variant="h5" color="initial" className="ilmond-title">
                Geopolitica economica e finanziaria
              </Typography>
            </div>
            <div className={classes.content}>
              <ReadMore>
                <Typography className={classes.text}>
                  Non è possibile intendere le grandi partite geopolitiche senza
                  considerarne gli aspetti economico-finanziari. Ma è necessario
                  comprendere che questi sono dominati dalla dimensione
                  geopolitica, non viceversa. Un esempio attuale, che ci tocca
                  da vicino, è quello della nostra adesione alle vie della seta
                  promosse da Pechino, inclusi investimenti strategici cinesi in
                  porti italiani e naturalmente la partita del 5G. Noi abbiamo
                  trattato con i cinesi come se il loro progetto fosse solo
                  economico, mentre si tratta di una strategia geopolitica a
                  tutto tondo, fondamentale nel progetto di fare della Cina il
                  leader mondiale entro il 2049. Risultato: non abbiamo
                  incassato un soldo da Pechino, ma firmando l’adesione al loro
                  progetto abbiamo provocato la reazione di Washington, del cui
                  impero informale siamo parte in seguito alla sconfitta nella
                  seconda guerra mondiale. In conseguenza della nostra adesione
                  “economica” alle vie della seta abbiamo subito ingiurie e
                  rappresaglie da parte americana. Per nostra deficienza di
                  analisi geopolitica abbiamo perso quindi su entrambi i fronti
                  in questo episodio della partita decisiva Usa-Cina, dal cui
                  esito dipende il nostro futuro. Attraverso le testimonianze di
                  protagonisti italiani, americani e cinesi, possiamo
                  ricostruire questo percorso e immaginare come si sarebbe
                  potuto (potrebbe) volgerlo a nostro favore.
                </Typography>
              </ReadMore>
            </div>
          </Grid>
          <Grid item md={6} sm={6}>
            <div className={classes.title} id="title">
              <Typography variant="h5" color="initial" className="ilmond-title">
                Codici interpretativi e negoziali
              </Typography>
            </div>
            <div className={classes.content}>
              <ReadMore>
                <Typography className={classes.text}>
                  Non esiste una lingua franca universale usata ovunque da
                  decisori e negoziatori geopolitici. Chiunque si sieda a un
                  tavolo fra soggetti di cultura, storia e interessi diversi
                  deve considerare le caratteristiche specifiche del loro modo
                  di ragionare e di negoziare. Conta il peso degli stereotipi
                  reciproci, per noi raramente positivi. Questo in Italia è
                  particolarmente difficile da accettare. Così ad esempio in
                  sede europea non abbiamo mai curato la nostra rappresentanza
                  nell’eurocrazia brussellese. Peggio: i nostri vengono istruiti
                  a postulare un “interesse europeo” che non esiste in natura,
                  mentre tutti gli altri hanno cura di rappresentare gli
                  interessi del proprio paese, vestendoli se necessario da
                  “europei” (Germania docet). Con la partecipazione di nostri
                  uomini attivi nei negoziati comunitari possiamo studiare e
                  simulare questo scenario, cercando di definire e difendere i
                  nostri interessi in casi specifici (es. Brexit o Recovery
                  Fund). Un esercizio utile sarà costringere i nostri a mettersi
                  nei panni dei loro interlocutori, recitandone la parte. Ciò
                  che nelle strutture analoghe di altri paesi è norma, da noi
                  non esiste. O ancora, per tornare alla Cina: lì chi negozia
                  non ha mai potere decisionale, deve sempre riferire a
                  un’istanza superiore (l’”uomo di dietro”). Soprattutto, un
                  accordo sottoscritto in buona e dovuta forma, per noi
                  considerato scopo finale con effetto immediato, è per un
                  cinese (e altri asiatici) inizio del negoziato. In un processo
                  sempre rivedibile, interminabile. Su questo possiamo mettere a
                  confronto esperti e decisori che hanno avuto a che fare con la
                  Repubblica Popolare.
                </Typography>
              </ReadMore>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TemiSection;
