import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import Video from "../../Videos/AZIENDE.mp4";
import image from "../../images/aboutVideo.png";
import ReactPlayer from "react-player";
const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
    height: "auto",
  },

  blog: {
    marginTop: 32,
    textAlign: "center",
    padding: theme.spacing(6, 12.5),
    backgroundColor: "#ccd8ee",
    borderTop: "5px solid #00406c",
    borderBottom: "5px solid #00406c",
    "& h4": {
      marginBottom: 8,
    },
    "& h6": {
      marginBottom: 16,
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  auth: {
    color: "#3c3c3b",
  },
  video: {
    marginBottom: 30,
  },
}));

const Block = () => {
  const classes = useStyles();
  return (
    <div className={classes.blog}>
      <Grid container spacing={3}>
        {/*  <Grid item md={6}>
          <img className={classes.image} src={placeholder} alt="placeholder" />
        </Grid> */}
        <Grid item md={12}>
          <Typography variant="h5" color="initial" className="video-title">
            Geopolitica per le aziende
          </Typography>
          <Typography
            variant="h6"
            color="initial"
            className={`auth-title ${classes.auth}`}
          >
            di Lucio Caracciolo
          </Typography>
          <Grid container className={classes.video}>
            <Grid item xs={12}>
              <div className="player-wrapper-iframe">
                <ReactPlayer
                  url={Video}
                  className="react-player"
                  light={image}
                  playing
                  width="100%"
                  height="100%"
                  controls={true}
                />
              </div>
            </Grid>
          </Grid>
          <Typography>
            Sempre più aziende hanno messo al centro del loro ragionamento la
            geopolitica. Oggi è evidente e lo sarà ancor più domani che le
            decisione sull’allocazione degli investimenti saranno largamente
            condizionate dai fattori geopolitici propri dell’area interessata.
            Per un paese con ramificazioni in tutto il mondo come il nostro
            questa è una necessità.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Block;
