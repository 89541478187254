import React from "react";
import AChiSiRivolge from "../../components/Chisiamo/AChiSiRivolge.jsx";
import CosaCiDistingue from "../../components/Chisiamo/CosaCiDistingue.jsx";
import CoverImage from "../../components/Chisiamo/CoverImage.jsx";
import INostriPartner from "../../components/Chisiamo/INostriPartner.jsx";
import PercheLaScuola from '../../components/Chisiamo/PercheLaScuola.jsx'

const Chisiamo = () => {
  return (
    <div>
      <CoverImage />
      <PercheLaScuola />
      <CosaCiDistingue />
      <AChiSiRivolge />
      <INostriPartner />
    </div>
  );
};

export default Chisiamo;
