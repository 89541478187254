import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Table from "./Table.jsx";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "auto",
    marginTop: 100,
    [theme.breakpoints.down("md")]: {
      marginTop: 40,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 20,
      flexDirection: "column",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: "42%",
  },
  left: {
    height: "51vh",
    overflowY: "auto",
    marginLeft: 20,
    marginTop: -40,

    "& h1": {
      marginTop: 0,
      marginBottom: 0,
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 56,
      marginTop: 0,
    },
  },
  link: {
    color: "#ef9100",
  },
  privicy: {
    display: "block",
  },
  title: {
    textAlign: "center",
    marginTop: "15px !important",
  },
  alfa: {
    listStyleType: "lower-alpha",
    "& li::marker": {
      fontWeight: "bold",
    },
  },
}));

const Candidarure = () => {
  const classes = useStyles();
  return (
    <div>
      <h1 className={classes.title}>
        INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI – FORM CANDIDATURA
      </h1>
      <p className="strong">
        <p>Introduzione</p>
      </p>
      <p className={classes.privicy}>
        La presente informativa è resa da{" "}
        <p className="strong">GEDI Periodici e Servizi S.p.A.</p>, con sede
        legale in Via Ernesto Lugaro, 15 – 10126, Torino, società del Gruppo
        GEDI, gruppo societario italiano operante nel settore dei media con
        attività nelle aree della stampa quotidiana e periodica, della
        radiofonia, della raccolta pubblicitaria e di Internet (per brevità “il{" "}
        <p className="strong">Gruppo</p>”), in qualità di titolare del
        trattamento (di seguito “il <p className="strong">Titolare</p>” o “
        <p className="strong">GPS</p>”), ai sensi degli artt. 13 e 14 del
        Regolamento (UE) 2016/679 (di seguito “<p className="strong">GDPR</p>
        ”).
      </p>

      <p className={classes.privicy}>
        Tale informativa è fornita allo scopo di illustrarle le finalità e le
        modalità con cui GPS raccoglie e tratta i dati personali, quali
        categorie di dati sono oggetto di trattamento, quali sono i diritti
        degli interessati e come possono essere esercitati.
      </p>

      <p className="strong">
        <p>Tipologia di dati trattati</p>
      </p>
      <p className={classes.privicy}>
        GPS tratterà i dati personali forniti nell’ambito della presentazione
        della candidatura e dell’iscrizione ai corsi della “Scuola di Limes”. In
        particolare, GPS potrà trattare i dati anagrafici (nome e cognome, data
        e luogo di nascita), indirizzo di posta elettronica, recapito
        telefonico, informazioni contenute nel suo curriculum vitae, nonché ogni
        altra informazione che sarà fornita durante la presentazione della
        candidatura e durante l’erogazione del corso. GPS non tratterà dati
        appartenenti a particolari categorie, ai sensi dell’art. 9 GDPR, salvo
        ciò non sia necessario per la corretta e proficua fruizione del corso.
        GPS tratterà i suoi dati nell’ambito della conclusione del contratto e
        al fine di consentirle la fruizione del corso anche tramite la
        piattaforma e-learning presente sul sito{" "}
        <a href="https://www.scuoladilimes.it"> www.scuoladilimes.it</a>.
      </p>
      <p className={classes.privicy}>
        Il trattamento dei dati personali sopra indicati è necessario per
        permetterle di presentare la sua candidatura e per la conclusione del
        contratto, nonché per consentirle di partecipare al corso e l’accesso
        alla piattaforma di e-learning; in difetto, GPS non sarà in grado di
        dare seguito alla sua richiesta.
      </p>
      <p className={classes.privicy}>
        I dati personali sopra indicati saranno trattati per le finalità e nei
        limiti di seguito indicati.
      </p>
      <p className="strong">
        <p>Finalità e base giuridica del trattamento</p>
      </p>
      <ul className={classes.alfa}>
        <li>
          <p className={`${classes.space} ${classes.privicy}`}>
            <p className="strong"> Per l’esecuzione del contratto</p>
          </p>

          <p className={classes.privicy}>
            I suoi dati saranno trattati da GPS per l’esecuzione del contratto e
            l’erogazione dei servizi richiesti, ai sensi dell’art. 6, lett. b)
            del GDPR. A titolo esemplificativo, i suoi dati saranno trattati al
            fine di effettuare l’iscrizione ed erogare il corso, nonché per la
            fruizione del materiale didattico e delle lezioni.
          </p>
        </li>

        <li>
          <p
            className="strong"
            className={`${classes.space} ${classes.privicy}`}
          >
            <p className="strong"> Per l’adempimento di obblighi di legge</p>
          </p>

          <p className={classes.privicy}>
            I suoi dati personali saranno trattati per l’adempimento di obblighi
            di legge, ai sensi dell’art. 6, lett. c) del GDPR. A titolo
            esemplificativo, GPS potrà trattare i suoi dati per adempimenti di
            natura fiscale connessi all’esecuzione del contratto.
          </p>
        </li>
        <li>
          <p
            className="strong"
            className={`${classes.space} ${classes.privicy}`}
          >
            {" "}
            <p className="strong">Per l’invio di comunicazioni commerciali</p>
          </p>

          <p className={classes.privicy}>
            Inoltre, in base ad un suo espresso e specifico consenso, ai sensi
            dell’art. 6, lett. a) del GDPR, GPS potrà trattare i suoi dati
            personali per l’invio di comunicazioni di natura commerciale
            sull’attività della Scuola e su propri prodotti e servizi, per
            iniziative promozionali, per svolgere indagini di mercato e analisi
            del livello di soddisfazione della clientela. L’invio delle
            comunicazioni commerciali potrà avvenire mediante l’utilizzo di
            sistemi tradizionali (es. posta cartacea) e di sistemi di
            comunicazione automatizzati (es. e-mail).
          </p>
          <p className={classes.privicy}>
            In ogni caso, per i soli iscritti ai corsi della Scuola di Limes,
            GPS, sulla base del proprio legittimo interesse, potrà trattare i
            suoi dati personali per l’invio di comunicazioni commerciali sulle
            attività e le iniziative della Scuola, nonché per l’offerta di
            alcuni prodotti o servizi analoghi a quelli oggetto del contratto,
            in modalità di soft spam, ai sensi dell’art. 130, comma 4, del D.
            Lgs. 196/2003 e s.m.i.. In questo caso, lei avrà il diritto di
            opporsi in qualsiasi momento al trattamento dei suoi dati personali
            per questa finalità.
          </p>
        </li>
      </ul>
      <p className="strong">
        <p>Durata del trattamento</p>
      </p>
      <p className={classes.privicy}>
        Riportiamo qui di seguito i periodi di utilizzo e conservazione dei suoi
        dati personali con riferimento alle diverse finalità di trattamento:
      </p>
      <ul className={classes.alfa}>
        <li>
          <p className={classes.privicy}>
            per le finalità connesse alla valutazione della sua candidatura, GPS
            tratterà i suoi dati personali solo per il tempo strettamente
            necessario per effettuare tale valutazione;
          </p>
        </li>
        <li>
          <p className={classes.privicy}>
            per le finalità connesse all’esecuzione del contratto e l'erogazione
            del corso, GPS tratterà i suoi dati per tutta la durata del rapporto
            e fino a che sussistano obbligazioni o adempimenti connessi
            all’esecuzione del contratto e saranno conservati per un periodo di
            10 anni successivo a tale termine, esclusivamente per finalità
            connesse all’adempimento di obblighi di legge e per consentire a GPS
            la difesa dei propri diritti in sede giudiziaria;
          </p>
        </li>
        <li>
          <p className={classes.privicy}>
            per l’adempimento di obblighi di legge, i dati saranno trattati e
            conservati da GPS finché persista la necessità del trattamento per
            adempiere a detti obblighi di legge;
          </p>
        </li>
        <li>
          <p className={classes.privicy}>
            per il trattamento per finalità di marketing e di invio di
            comunicazioni commerciali, GPS tratterà i suoi dati personali fino
            alla revoca del suo consenso. Le ricordiamo che lei ha il diritto di
            revocare il consenso prestato in qualsiasi momento, scrivendo a {" "}
            <a href="mailto:privacy@gedigps.it"> privacy@gedigps.it</a> -
            specificando nell’oggetto della comunicazione
            <p className="strong">Scuola di LIMES</p>”;
          </p>
        </li>
        <li>
          <p className={classes.privicy}>
            per l’invio di comunicazioni informative e commerciali sulla base
            del legittimo interesse del Titolare (soft spam), i dati saranno
            trattati finché lei non si opponga a tale trattamento.
          </p>
        </li>
      </ul>
      <p className={classes.privicy}>
        <p className="strong">
          Categorie di soggetti ai quali i dati personali possono essere
          comunicati e finalità della comunicazione
        </p>
      </p>
      <p className={classes.privicy}>
        Alcuni trattamenti di dati personali potranno essere effettuati anche da
        soggetti terzi ai quali GPS affida la fornitura di alcuni servizi. In
        tal caso, questi soggetti saranno designati Responsabili del
        trattamento, ai sensi dell’art. 28 del GDPR, e riceveranno adeguate
        istruzioni operative, con particolare riferimento all’adozione delle
        misure tecniche e organizzative idonee a garantire la sicurezza dei dati
        oggetto di trattamento, al fine di poter garantire la riservatezza e la
        sicurezza dei dati.
      </p>
      <p className={classes.privicy}>
      Qualora sia previsto dallo specifico corso della “Scuola di Limes”, GPS potrà altresì comunicare i
      dati personali dei partecipanti alle aziende partner al fine di consentire a queste ultime la
      valutazione del profilo degli stessi partecipanti, finalizzata all’eventuale partecipazione a stage
      formativi organizzati al termine del corso.</p>
      <p className={classes.privicy}>
        L’elenco dei Responsabili del trattamento può essere richiesto a GPS
        scrivendo a <a href="mailto:privacy@gedigps.it">privacy@gedigps.it</a> -
        specificando nell’oggetto della comunicazione “
        <p className="strong">Scuola di LIMES</p>”.
      </p>
      <p className={classes.privicy}>
        Non è prevista alcuna forma di diffusione dei suoi dati a soggetti
        indeterminati.
      </p>
      <p className={classes.privicy}>
        <p className="strong">Trattamento dei dati fuori dell’Unione europea</p>
      </p>
      <p className={classes.privicy}>
        GPS potrebbe trasferire i suoi dati personali a terze parti ubicate in
        Stati non appartenenti all'Unione Europea ovvero allo Spazio Economico
        Europeo. In tale circostanza, tali terze parti saranno nominate
        Responsabili del trattamento ai sensi e per gli effetti di cui
        all’articolo 28 del GDPR e il trasferimento dei suoi dati personali a
        tali soggetti, limitatamente allo svolgimento di specifiche attività di
        trattamento, sarà regolato in conformità a quanto previsto dal capo V
        del GDPR. Saranno quindi adottate tutte le cautele necessarie al fine di
        garantire adeguata protezione dei suoi dati personali basando tale
        trasferimento: (a) su decisioni di adeguatezza dei paesi terzi
        destinatari espresse dalla Commissione Europea; (b) su garanzie adeguate
        espresse dal soggetto terzo destinatario ai sensi dell’articolo 46 del
        GDPR; (c) sull’adozione di norme vincolanti d’impresa, c.d. corporate
        binding rules. Gli utenti possono richiedere informazioni in merito a
        tali garanzie inoltrando richiesta a{" "}
        <a href="mailto:privacy@gedigps.it"> privacy@gedigps.it</a> -
        specificando nell’oggetto della comunicazione “
        <p className="strong">Scuola di LIMES</p>”.
      </p>
      <p className={classes.privicy}>
        A seguito della pronuncia della Corte di Giustizia dell’Unione Europea
        del 16 luglio 2020 nella causa C311/18, con riferimento ai paesi terzi
        che non sono destinatari di una delle decisioni di adeguatezza da parte
        della Commissione Europea, GPS effettua valutazioni specifiche in merito
        alle garanzie supplementari da adottare, al fine di prevenire
        interferenze da parte di autorità pubbliche locali che possano
        pregiudicare il livello di essenziale equivalenza di protezione dei dati
        ivi trasferiti, rispetto al livello goduto in base al diritto europeo.
      </p>
      <p className={classes.privicy}>
        <p className="strong">Diritti degli interessati</p>
      </p>
      <p className={classes.privicy}>
        La informiamo che, in relazione al trattamento dei suoi dati personali,
        potrà esercitare i diritti di cui agli articoli 15 e ss del GDPR
        (accesso, portabilità, opposizione, rettifica, limitazione e
        cancellazione), nonché il diritto di revoca del consenso (ex art. 7 del
        GDPR), rivolgendosi al Titolare del trattamento tramite e-mail
        all’indirizzo
        <a href="mailto:privacy@gedigps.it"> privacy@gedigps.it</a> -
        specificando nell’oggetto della comunicazione “
        <p className="strong">Scuola di LIMES</p>”.
      </p>
      <p className={classes.privicy}>
        Le comunichiamo, inoltre, che all’interno del Gruppo è stato
        identificato un Data Protection Officer, contattabile al seguente
        indirizzo mail:<a href="mailto:dpo@gedi.it"> dpo@gedi.it</a>.
      </p>
      <p className={classes.privicy}>
        La informiamo infine che ai sensi della disciplina vigente può proporre
        eventuali reclami riguardanti i trattamenti di suoi dati personali al
        Garante per la protezione dei dati personali.
      </p>
    </div>
  );
};

export default Candidarure;
