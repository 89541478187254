import React from "react";
import {
  Typography,
  makeStyles,
  Divider,
  Grid,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
}));

const Ammissione = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          AMMISSIONE
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Per candidarsi occorre inviare il curriculum e una lettera di motivazione: perché si vuole partecipare alla Scuola di Limes? Che cosa ci si aspetta dal corso? In che modo la Scuola può aiutare il proprio percorso di carriera?
              Se necessario, la Direzione contatterà i candidati per un colloquio.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Ammissione;
