import React from "react";
import { Typography, makeStyles, Divider, Grid } from "@material-ui/core";
import bannerMobile from "../../images/banner-mondo-di-limes_mobile.png";
import maincolors from "../Colors/Color";
import LucioVideo from "../../Videos/lucio_09_11.mp4";
import banner from "../../images/banner-mondo-di-limes.png";
import ReactPlayer from "react-player";
import image1 from "../../images/image1.png";
import image2 from "../../images/image2.png";
import image3 from "../../images/image3.png";
import image4 from "../../images/image4.png";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  grow: {
    marginTop: 56.5,
    marginBottom: 32,
    [theme.breakpoints.down("sm")]: {
      marginTop: 32,
    },

    "& > div ": {
      padding: 20,
      marginBottom: 32,

      "& p": {
        paddingTop: 8,
      },
      "& h5": {
        fontWeight: "bold",
      },
      "& a": {
        display: "block",
        paddingTop: 16,
        color: maincolors.orange,
      },
    },
  },
  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
  link: {
    display: "block",
  },
  mondo: {
    backgroundColor: "#ccd8ee",
    paddingTop: 15,
    paddingBottom: 80,
    position: "relative",
    borderTop: "5px solid #00406c",
    borderBottom: "5px solid #00406c",

    "& hr": {
      height: 5,
      backgroundColor: "#00406c",
    },
  },
  expended: {
    marginTop: 48,
    marginBottom: 20,
  },
  expendedtext: {
    marginBottom: 20,
  },

  rec: {
    marginTop: 0,
  },
  mondoTitle: {
    paddingTop: 32,
  },
  image: {
    maxWidth: "100%",
    height: "auto",
  },
  sectionTitle: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
}));

const IlmondoDiLimes = () => {
  const classes = useStyles();

  return (
    <div className={classes.mondo}>
      <Typography
        className={`section-title ${classes.sectionTitle}`}
        variant="h1"
        color="initial"
      >
        il mondo di limes
      </Typography>
      <Divider />
      <img className={`banner ${classes.image}`} src={banner} alt="banner" />
      <img
        className={`banner-logo ${classes.image}`}
        src={bannerMobile}
        alt="banner"
      />
      <div className={classes.block}>
        <Grid container>
          <Grid item md={12}>
            <Typography
              className={`section-subtitle ${classes.mondoTitle} `}
              variant="h2"
              color="initial"
            >
              La rivista
            </Typography>
            <Typography className={`${classes.text}`}>
              Limes è la rivista italiana di geopolitica fondata nel 1993 e da
              allora diretta da Lucio Caracciolo. Studia le principali
              competizioni tra i grandi attori della geopolitica mondiale
              mettendo a confronto punti di vista diversi e contrastanti.
              Incrocia competenze e approcci di studiosi di varia estrazione
              (storici, geografi, antropologi) con quelli di decisori (politici,
              strateghi, militari, diplomatici, imprenditori) che prendono parte
              ai conflitti in esame. Ogni mese, con volumi monografici,
              approfondisce uno specifico caso geopolitico.
            </Typography>

            <Typography
              variant="h2"
              color="initial"
              className={`section-subtitle`}
            >
              La cartografia
            </Typography>

            <Typography className={`${classes.text}`}>
              Le carte sono lo strumento fondamentale della geopolitica. Sono
              sia strumento analitico sia oggetto di potere. Servono sia a
              capire chi si confronta per cosa sia a schizzare gli scopi che i
              contendenti intendono raggiungere. Tutte mentono:
              nell’impossibilità di rappresentare la realtà, rappresentano punti
              di vista e obiettivi delle parti in gioco. La nostra produzione
              cartografica, opera di Laura Canali, orienterà i dibattiti della
              scuola.
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={"videos-section"}>
          <Grid item md={12}>
            <Typography
              variant="h2"
              color="initial"
              className={`section-subtitle`}
            >
              Uno sguardo nella Scuola
            </Typography>
            <br />
          </Grid>
          <Grid container spacing={3} className="media centered-video">
          
            <Grid item md={6} sm={6} xs={12}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={`https://player.vimeo.com/video/766797654?h=5e3423d8b6&title=0&byline=0&portrait=0`}
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls={true}
                  light={image1}
                  playing
                />
              </div>
              <Typography variant="h5" color="initial" className="ilmond-title">
                Nell'anticamera del potere
              </Typography>
              <Typography className={`${classes.text}`}>
                Lucio Caracciolo e il Generale Giuseppe Cucchi sul ruolo del pre-consiglio in Italia.
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={`https://player.vimeo.com/video/766796465?h=fd80eaf797&title=0&byline=0&portrait=0`}
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls={true}
                  light={image2}
                  playing
                />
              </div>
              <Typography variant="h5" color="initial" className="ilmond-title">
                Fare geopolitica
              </Typography>
              <Typography className={`${classes.text}`}>
                Come scrivere un’analisi geopolitica.
              </Typography>
            </Grid>




            <Grid item md={6} sm={6} xs={12}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={`https://player.vimeo.com/video/766813128?h=c498c079bd&title=0&byline=0&portrait=0`}
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls={true}
                  light={image3}
                  playing
                />
              </div>
              <Typography variant="h5" color="initial" className="ilmond-title">
                I codici interpretativi
              </Typography>
              <Typography className={`${classes.text}`}>
                Il direttore Lucio Caracciolo dibatte lo scontro Usa-Cina con il sinologo Francesco Sisci.
              </Typography>
            </Grid>
            <Grid item md={6} sm={6} xs={12}>
              <div className="player-wrapper">
                <ReactPlayer
                  url={`https://player.vimeo.com/video/766794353?h=8f60a6efbb&title=0&byline=0&portrait=0`}
                  className="react-player"
                  width="100%"
                  height="100%"
                  controls={true}
                  light={image4}
                  playing
                />
              </div>
              <Typography variant="h5" color="initial" className="ilmond-title">
                Come si negozia
              </Typography>
              <Typography className={`${classes.text}`}>
                Perchè la lingua è potere in un negoziato geopolitico.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default IlmondoDiLimes;
