import React from "react";
import { Typography, TextField, Button, Grid } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core";
import { useForm } from "react-hook-form";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const useStyles = makeStyles((theme) => ({
  grow: {
    backgroundColor: "#ccd8ee",
    padding: "53px 112px",
    [theme.breakpoints.down("md")]: {
      padding: 40,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
  },
  input: {
    backgroundColor: "#fff",
    marginRight: 20,
    width: "100%",
    marginBottom: "1rem",
    "&:nth-child(5)": {
      width: "auto",
    },
    "& div": {
      borderRadius: 0,
    },
  },
  textArea: {
    backgroundColor: "#fff",
    marginRight: 0,
    width: "auto !important",
    marginBottom: "1rem",
  },
  title: {
    marginBottom: 50,
    [theme.breakpoints.down("md")]: {
      marginBottom: 40,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  upload: {
    borderRadius: 0,
  },
  name: {
    width: "100%",
  },
  button: {
    marginTop: 16,
    backgroundColor: "#00416b",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#00416b",
      color: "#fff",
    },
  },
  uploadfile: {
    display: "flex",
  },
  label: {
    paddingBottom: 8,
  },
  validation: {
    marginBottom: 16,
    display: "block",
    color: "red",
  },
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  labellink: {
    color: "#00416b",
  },
}));
const onSubmit = (data) => {
  console.log("ABout to submit ", data);
  var isValid = true; // TODO: perform validation
  if (!isValid) {
    e.preventDefault();
  }

  fetch("/submit_contact", {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(data),
  })
    .then((res) => {
      if (res.status < 400) {
        window.location.href = "/grazie-contatti";
      } else {
        console.error(`A server error occurred: ${res.status}`);
        alert("Siamo spiacenti, si è verificato un errore");
      }
    })
    .catch((err) => {
      console.error(err);
      alert(
        "Siamo spiacenti, si è verificato un errore di connessione. La preghiamo di riprovare più tardi"
      );
    });
};
const ContactForm = () => {
  const classes = useStyles();
  const { register, errors, handleSubmit, control } = useForm();
  const [value, setValue] = React.useState("");
  const [option, setOption] = React.useState();

  const handleChangeSelect = (event) => {
    setOption(event.target.value);
  };

  const handleValueChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div id="contact-form">
      <div className={classes.grow}>
        <Typography className={classes.title} variant="h1">
          CONTATTACI
        </Typography>
        <div className="form_warpper">
          <form
            method="POST"
            action="/submit_contact"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="dropdown">
              <label className={classes.label}>Come possiamo aiutarti? *</label>
              <select
                required
                name="subject"
                value={option}
                defaultValue="Scegli il tema"
                onChange={handleChangeSelect}
                ref={register({
                  required: true,
                })}
              >
                <option value="Scegli il tema" disabled="disabled">
                  Scegli il tema
                </option>
                <option value="I corsi in presenza">I corsi in presenza</option>
                <option value="I corsi da remoto">I corsi da remoto</option>
                <option value="I pagamenti">I pagamenti</option>
                <option value="La mia area riservata">
                  La mia area riservata
                </option>
                <option value="Finanziamento del corso">
                  Finanziamento del corso
                </option>
                <option value="Altro">Altro</option>
              </select>
            </div>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12}>
                <label className={classes.label}>Il tuo nome *</label>

                <TextField
                  name="nome"
                  required={true}
                  className={`${classes.name} ${classes.input}`}
                  placeholder="Nome"
                  variant="outlined"
                  inputRef={register({
                    pattern: /^[^\d"@!?/|]+$/i,
                    minLength: 4,
                  })}
                />
                <span className={classes.validation}>
                  {errors.nome &&
                    'Il nome non può contenere nessuno di questi caratteri: " @ ! ? / | 1 3 5 '}
                </span>
              </Grid>
              <Grid item sm={6} xs={12}>
                <label className={classes.label}>Il tuo cognome *</label>

                <TextField
                  name="cognome"
                  required={true}
                  className={classes.input}
                  placeholder="Cognome"
                  variant="outlined"
                  inputRef={register({
                    pattern: /^[^\d"@!?/|]+$/i,
                    minLength: 4,
                  })}
                />
                <span className={classes.validation}>
                  {errors.cognome &&
                    'Il nome non può contenere nessuno di questi caratteri: " @ ! ? / | 1 3 5 '}
                </span>
              </Grid>
            </Grid>

            <label className={classes.label}>Email *</label>
            <TextField
              fullWidth
              name="email"
              type="email"
              required={true}
              inputRef={register({
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
              className={classes.input}
              placeholder="Email"
              variant="outlined"
            />
            <span className={classes.validation}>
              {errors.email &&
                "L'email non può contenere nessuno di questi caratteri: ! # $ % ^ "}
            </span>
            <div className="form_container_end_textarea">
              <label className={classes.label}>Messaggio *</label>
              <TextareaAutosize
                name="messaggio"
                className={classes.textArea}
                rowsMax={16}
                aria-label="maximum height"
                placeholder="Compila questo campo se hai delle richieste specifiche"
                ref={register({ minLength: 10 })}
              />
              <span className={classes.validation}>
                {errors.messaggio && "Il messaggio è troppo corto"}
              </span>
            </div>
            <Typography className={classes.label}>
              * Campi obbligatori
            </Typography>
            <div className={`terms-forms`}>
              <label>
                <span>
                  Proseguendo, dichiari di aver letto e compreso{" "}
                  <a className={classes.labellink} href="./contatto">
                    l'informativa sulla privacy
                  </a>
                </span>
              </label>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    required={false}
                    name="GEDI_accepted"
                    value="GEDI_accepted"
                    color="primary"
                    inputRef={register()}
                  />
                }
                label={
                  <span>
                    Cliccando qui, presti il tuo consenso a che GEDI Periodici e
                    Servizi S.p.A. tratti i tuoi dati personali per l’invio di
                    comunicazioni commerciali inerenti alle attività della
                    Scuola di Limes e ai propri prodotti e servizi.
                  </span>
                }
              />
              <br />

              <Button className={classes.button} type="submit">
                INVIA
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
