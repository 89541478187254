import React from "react";
import {
  Typography,
  makeStyles,
  Divider,
  Grid,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },

  text: {
    paddingTop: "1rem",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
}));

const Club = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          ALUMNI DI LIMES
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Agli studenti che avranno seguito il corso sarà offerta la
              possibilità di iscriversi, al termine dello stesso, al Club Alumni
              di Limes per rimanere in contatto con il corpo docente, i colleghi
              e per accedere a servizi loro dedicati.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Club;
