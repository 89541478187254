import React, { createRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../images/Logo-Scuola-Limes.png";
import MobileLogo from "../../images/header-scuola-di-limes-mobile.png";
import {
  Container,
  Link,
  List,
  Button,
  Divider,
  IconButton,
  Hidden,
  Drawer,
  ListItem,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { Link as RouterLink } from "react-router-dom";
import { NavLink } from "react-router-dom";
import maincolors from "../Colors/Color";
import CloseIcon from "@material-ui/icons/Close";
import SecondaryBanner from "../Homepage/SecondaryBanner.jsx";
import useHideOnScrolled from "../Layout/useHideOnScrolled.jsx";
import { useLocation } from "react-router-dom";
import useResizeObserver from "use-resize-observer";

const drawerWidth = "75%";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  navbar: {
    backgroundColor: maincolors.white,
    boxShadow: "none",
    marginBottom: theme.spacing(0),
  },

  logo: {
    maxWidth: "100%",
    height: "auto",

    position: "relative",
  },
  menuWrapper: {
    width: "100%",
    position: "fixed",
    background: "#fff",
    zIndex: 99,
  },
  menu: {
    display: "flex",
    justifyContent: "center",
    padding: 0,

    "& a": {
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "8px 16px",
      color: "#000",
      textDecoration: "none",
      borderLeft: `1px solid ${maincolors.gray}`,
      borderBottom: `1px solid ${maincolors.gray}`,

      "&:last-child": {
        borderRight: `1px solid ${maincolors.gray}`,
      },

      [theme.breakpoints.down("sm")]: {
        paddingTop: theme.spacing(1.2),
        height: "auto",
        display: "flex",
      },

      [theme.breakpoints.down("sm")]: {
        whiteSpace: "pre-wrap",
      },
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  button: {
    backgroundColor: maincolors.darkblue,
    color: maincolors.white,
    height: 48,
    top: "calc(50% - 48px)",
    right: 100,
    position: "absolute",
    padding: "8px 16px",
    borderRadius: 0,
    "&:hover": {
      color: maincolors.white,
      backgroundColor: maincolors.darkblue,
    },
    [theme.breakpoints.down("sm")]: {
      height: 29,
      right: 40,
      top: "calc(50% - 40px)",
      marginLeft: 20,
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },

  buttonList: {
    backgroundColor: maincolors.darkblue,
    color: maincolors.white,
    borderRadius: 0,
    margin: theme.spacing(2, 0, 2, 2),
    "&:hover": {
      color: maincolors.white,
      backgroundColor: maincolors.darkblue,
    },
  },

  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  languages: {
    display: "flex",
  },
  toolbar: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
      minHeight: 0,
    },
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  right: {
    width: "50%",
  },
  blue: {
    backgroundColor: maincolors.darkblue,
    height: 41,
  },
  orange: {
    height: 48,
    backgroundColor: maincolors.orange,
  },
  left: {
    width: "50%",
    display: "block",
  },
  title: {
    position: "absolute",
    top: "22%",
    right: "60%",

    [theme.breakpoints.down("xs")]: {
      top: "42%",
      right: "64%",

      marginTop: 2,
    },
  },
  drawer: {
    "& li > a": {
      color: maincolors.darkblue,
    },
  },
  closeicon: {
    float: "right",
    paddingRight: theme.spacing(2),
    color: maincolors.darkblue,
    fontSize: "2rem",
  },
  logowrap: {
    lineHeight: 0,
  },
  banner: {
    backgroundColor: "#CCD8EE",
    color: "#00406c",
    padding: "10px 0",
    width: "100%",

    "& P": {
      display: "flex",
      justifyContent: "center",
      padding: "4px 0",
      letterSpacing: 0.96,
      [theme.breakpoints.down("sm")]: {
        height: 22,
      },
      [theme.breakpoints.down("xs")]: {
        height: "auto",
      },
    },
  },
  topHeader: {
    position: "relative",
    lineHeight: 0,
  },
  divider: {
    margin: "0 !important",
  },
  MobileLogo: {
    maxWidth: "100%",
    height: "auto",
    position: "relative",
  },
}));

export default function PrimarySearchAppBar() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { ref, height } = useResizeObserver();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const path = () => {
    return <SecondaryBanner />;
  };
  const showPath = path();

  const drawer = (
    <div className={classes.drawer}>
      <List>
        <CloseIcon className={classes.closeicon} onClick={handleDrawerToggle} />
        <ListItem>
          <Link className="menu-item" component={RouterLink} to={"/home"}>
            HOME
          </Link>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem>
          <Link className="menu-item" component={RouterLink} to={"/chisiamo"}>
            CHI SIAMO
          </Link>
        </ListItem>
        <Divider className={classes.divider} />
        <ListItem>
          <Link className="menu-item" component={RouterLink} to={"/icorsi"}>
            I CORSI
          </Link>
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem>
          <Link className="menu-item" component={RouterLink} to={"/docenti"}>
            I DOCENTI
          </Link>
        </ListItem>
        <Divider className={classes.divider} />

        <ListItem>
          <Link className="menu-item" component={RouterLink} to={"/contatti"}>
            CONTATTI
          </Link>
        </ListItem>
      </List>
      <Divider className={classes.divider} />
      <ListItem>
        <Link className="menu-item" component={RouterLink} to={"/perleaziende"}>
          PER LE AZIENDE
        </Link>
      </ListItem>
      <Divider className={classes.divider} />
      <Link href="/login">
        <Button
          className={classes.buttonList}
          variant="contained"
          color="default"
        >
          Area riservata
        </Button>
      </Link>
    </div>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="fixed" className={classes.navbar} id="header">
        <Container
          maxWidth={false}
          className="app-container"
          disableGutters={true}
        >
          <Toolbar className={classes.toolbar}>
            <div className={classes.topHeader} id="js-header" ref={ref}>
              <Link
                to="/home"
                component={RouterLink}
                className={classes.logowrap}
              >
                <img src={Logo} alt="logo" className={`logo ${classes.logo}`} />
                <img
                  src={MobileLogo}
                  alt="logo"
                  className={`mobile-logo ${classes.MobileLogo}`}
                />
              </Link>
              <IconButton
                aria-label="Open drawer"
                onClick={handleDrawerToggle}
                className="burger_menu"
              >
                <MenuIcon />
              </IconButton>

              <Link href="/login">
                <Button
                  className="header-button"
                  variant="contained"
                  color="default"
                >
                  Area riservata
                </Button>
              </Link>
            </div>
          </Toolbar>
        </Container>
      </AppBar>

      <div className="background-wrapper">
        <div className="logo-background" />
      </div>

      <Container
        maxWidth={false}
        className="app-container"
        disableGutters={true}
      >
        <div
          className={`app-container ${classes.menuWrapper}`}
          style={{ top: height }}
        >
          <div className={classes.menu}>
            <NavLink className="menu-item" to={"/home"}>
              HOME
            </NavLink>
            <NavLink className="menu-item" to={"/chisiamo"}>
              CHI SIAMO
            </NavLink>
            <NavLink to={"/icorsi"} className="menu-item">
              I CORSI
            </NavLink>

            <NavLink className="menu-item" to={"/docenti"}>
              I DOCENTI
            </NavLink>

            <NavLink className="menu-item" to={"/contatti"}>
              CONTATTI
            </NavLink>
            <NavLink className="menu-item" to={"/perleaziende"}>
              PER LE AZIENDE
            </NavLink>
          </div>
          {showPath}
        </div>
      </Container>

      <Hidden mdUp>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </div>
  );
}
