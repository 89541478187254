import React from "react";
import { makeStyles } from "@material-ui/core";
import maincolors from "../Colors/Color";

const useStyles = makeStyles((theme) => ({
  rectangle: {
    width: 80,
    height: 56,

    float: "left",
    backgroundColor: maincolors.orange,
    [theme.breakpoints.down("lg")]: {
      width: 80,
      height: 41,
    },
    [theme.breakpoints.down("md")]: {
      width: 40,
      height: 41,
    },
    [theme.breakpoints.down("sm")]: {
      width: 20,
      height: 34,
      marginTop: 9,
    },
    [theme.breakpoints.down("sm")]: {
      width: 20,
      height: 25,
      marginTop: 9,
    },
  },
}));

function Rectangle() {
  const classes = useStyles();
  return <div id="rectangle" className={classes.rectangle} />;
}

export default Rectangle;
