import React from "react";
import { Typography, TextField, Button, Grid } from "@material-ui/core";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useForm } from "react-hook-form";

const options = [
  "Agricoltura e allevamento",
  "Ambiente e tutela del territorio",
  "Amministrazione, finanza e controllo d’impresa",
  "Artigianato artistico",
  "Attività commerciale",
  "Audiovisivi e spettacolo",
  "Beni culturali",
  "Chimica",
  "Commerciale e marketing",
  "Comunicazione e giornalismo",
  "Edilizia e urbanistica",
  "Educazione e formazione",
  "Forze armate e sicurezza",
  "Gestione risorse umane",
  "Grafica ed editoria",
  "Grande distribuzione",
  "Industria agroalimentare",
  "Informatica ed elettronica",
  "Metalmeccanica",
  "Moda e abbigliamento",
  "Non profit",
  "Pubblica amministrazione",
  "Pubblicità",
  "Ristorazione e alimentazione",
  "Servizi finanziari e assicurativi",
  "Servizi sanitari",
  "Servizi sociali",
  "Sport",
  "Telecomunicazioni",
  "Trasporti",
  "Turismo, ospitalità e tempo libero",
];

const useStyles = makeStyles((theme) => ({
  grow: {
    backgroundColor: "#ccd8ee",
    padding: "53px 112px",
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 40,
    },
  },
  input: {
    backgroundColor: "#fff",
    marginRight: 20,
    width: "100%",
    marginBottom: "1rem",
    "&:nth-child(4)": {
      width: "auto",
    },
    "& div": {
      borderRadius: 0,
    },
  },
  title: {
    marginBottom: 50,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 40,
    },
  },
  upload: {
    borderRadius: 0,
  },
  button: {
    marginTop: 16,
    backgroundColor: "#00416b",
    color: "#fff",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#00416b",
      color: "#fff",
    },
  },
  uploadfile: {
    display: "flex",
  },
  label: {
    paddingBottom: 8,
  },
  validation: {
    marginBottom: 16,
    display: "block",
    color: "red",
  },
  formControl: {
    marginBottom: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  labellink: {
    color: "#00416b",
  },
  next: {
    marginLeft: 16,
    [theme.breakpoints.down("xs")]: {
      marginLeft: 0,
    },
  },
}));
const onSubmit = (data) => {
  var isValid = true; // TODO: perform validation
  if (!isValid) {
    e.preventDefault();
  }

  fetch("/submit_contact", {
    method: "POST",
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams(data),
  })
    .then((res) => {
      if (res.status < 400) {
        window.location.href = "/grazie-contatti";
      } else {
        console.error(`A server error occurred: ${res.status}`);
        alert("Siamo spiacenti, si è verificato un errore");
      }
    })
    .catch((err) => {
      console.error(err);
      alert(
        "Siamo spiacenti, si è verificato un errore di connessione. La preghiamo di riprovare più tardi"
      );
    });
};
const ContactForm = () => {
  const classes = useStyles();
  const { register, errors, handleSubmit, control } = useForm();
  const [value, setValue] = React.useState("");
  const [option, setOption] = React.useState();

  const handleChangeSelect = (event) => {
    setOption(event.target.value);
  };
  const handleValueChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div id="contact-form">
      <div className={classes.grow}>
        <Typography className={classes.title} variant="h1">
          SEI UN'AZIENDA? <br /> CONTATTACI
        </Typography>
        <div className="form_warpper">
          <form
            method="POST"
            action="/submit_contact"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Grid container spacing={2} className="form-continer">
              <Grid item sm={3}>
                <div className={classes.spacing}>
                  <label className={classes.label}>Nome *</label>
                  <TextField
                    name="nome"
                    required={true}
                    className={classes.input}
                    placeholder="Inserisci"
                    variant="outlined"
                    inputRef={register()}
                  />
                </div>
              </Grid>
              <Grid item sm={3}>
                <div className={classes.spacing}>
                  <label className={classes.label}>Cognome *</label>
                  <TextField
                    name="cognome"
                    required={true}
                    className={classes.input}
                    placeholder="Inserisci"
                    variant="outlined"
                    inputRef={register()}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="form-continer">
              <Grid item sm={3}>
                <div className={classes.spacing}>
                  <label className={classes.label}>Azienda *</label>
                  <TextField
                    name="azienda"
                    required={true}
                    className={classes.input}
                    placeholder="Inserisci"
                    variant="outlined"
                    inputRef={register()}
                  />
                </div>
              </Grid>
              <Grid item sm={3}>
                <div className={classes.spacing}>
                  <label className={classes.label}>Ruolo *</label>
                  <TextField
                    name="ruolo"
                    required={true}
                    className={classes.input}
                    placeholder="Inserisci"
                    variant="outlined"
                    inputRef={register()}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={6}>
                <div className="dropdown">
                  <label className={classes.label}>Settore *</label>
                  <select
                    required
                    name="subject"
                    value={option}
                    onChange={handleChangeSelect}
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value="" selected="true" disabled="disabled" hidden>
                      Selezionare il settore
                    </option>
                    {options.map((option) => (
                      <option value={option} key={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </Grid>
            <Grid container className="form-continer-email">
              <Grid item sm={6}>
                <div className={classes.spacingEmail}>
                  <label className={classes.label}>Email *</label>
                  <TextField
                    name="email"
                    type="email"
                    fullWidth
                    required={true}
                    inputRef={register({
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    })}
                    className={classes.input}
                    placeholder="Email"
                    variant="outlined"
                  />
                  <span className={classes.validation}>
                    {errors.email &&
                      "L'email non può contenere nessuno di questi caratteri: ! # $ % ^ "}
                  </span>
                </div>
              </Grid>
              <Grid item sm={4} className={classes.next}>
                <div className={classes.spacing}>
                  <label className={classes.label}>Numero di telefono </label>
                  <TextField
                    name="telefono"
                    type="tel"
                    required={true}
                    className={classes.input}
                    placeholder="Inserisci"
                    variant="outlined"
                    inputRef={register({
                      minLength: 4,
                    })}
                  />

                  <span className={classes.validation}>
                    {errors.ruolo && "Il numero di telefono è troppo corto"}
                  </span>
                </div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item sm={10} className="form-textArea">
                <div className="form_textarea">
                  <label className={classes.label}>Messaggio *</label>
                  <TextareaAutosize
                    name="messaggio"
                    className={classes.input}
                    rowsMax={16}
                    aria-label="maximum height"
                    placeholder="Compila questo campo se hai delle richieste specifiche"
                    ref={register({ minLength: 10 })}
                  />
                  <span className={classes.validation}>
                    {errors.messaggio && "Il messaggio è troppo corto"}
                  </span>
                </div>
              </Grid>
            </Grid>
            <Typography className={classes.label}>
              * Campi obbligatori
            </Typography>
            <div className={`terms-forms`}>
              <label>
                <span>
                  Proseguendo, dichiari di aver letto e compreso{" "}
                  <a className={classes.labellink} href="./paginadocumenti">
                    l'informativa sulla privacy
                  </a>
                </span>
              </label>
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    required={false}
                    name="GEDI_accepted"
                    value="GEDI_accepted"
                    color="primary"
                    inputRef={register()}
                  />
                }
                label={
                  <span>
                    Cliccando qui, presti il tuo consenso a che GEDI Periodici e
                    Servizi S.p.A. tratti i tuoi dati personali per l’invio di
                    comunicazioni commerciali inerenti alle attività della
                    Scuola di Limes e ai propri prodotti e servizi.
                  </span>
                }
              />
              <br />
              <Button className={classes.button} type="submit">
                INVIA
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
