import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";

import maincolors from "../Colors/Color.js";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    paddingTop: "1rem",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
}));

const AChiSiRivolge = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
        A CHI CI RIVOLGIAMO
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              La Scuola di Limes è aperta a ogni fascia d’età. Si divide in
              classe iunior e classe senior. Selezioniamo laureandi,
              neolaureati, giovani lavoratori, dirigenti pubblici e privati,
              imprenditori, funzionari dello Stato, docenti, professionisti,
              operatori della finanza. La geopolitica è multidisciplinare per
              natura. Anche la composizione delle classi lo è, per attingere
              alla più ampia gamma di saperi possibile. Siamo aperti a chiunque
              abbia interesse e passione per il nostro approccio, per gli affari
              di Stato, per la proiezione internazionale delle nostre aziende.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AChiSiRivolge;
