import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 8,
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    paddingTop: 15,
  },
  show: {
    color: "#00406c",
    cursor: "pointer",
  },
}));
function ReadMore({ children }) {
  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  return (
    <React.Fragment>
      <div className={isHidden ? classes.hidden : null}>{children}</div>
      <a
        className={classes.show}
        size="small"
        onClick={() => setIsHidden(!isHidden)}
      >
        {isHidden ? "Continua a leggere..." : "Mostra meno"}
      </a>
    </React.Fragment>
  );
}

export default ReadMore;
