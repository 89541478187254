import React from "react";
import CoverImage from "../../components/perleaziende/CoverImage.jsx";
import LIMESPERLEAZIENDESection from "../../components/perleaziende/LIMESPERLEAZIENDESection.jsx";
import ContactForm from "../../components/perleaziende/ContactForm.jsx";

const Perleaziende = () => {
  return (
    <div>
      <CoverImage />
      <LIMESPERLEAZIENDESection />
      <ContactForm />
    </div>
  );
};

export default Perleaziende;
