import React from "react";
import {
  makeStyles,
  Divider,
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
} from "@material-ui/core";

import { Link } from "react-router-dom";
import maincolors from "../Colors/Color.js";
import Rectangle from "../Layout/Rectangle.jsx";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& a": {
      display: "block",
      paddingTop: 16,
      color: maincolors.orange,

      marginBottom: 16,
      [theme.breakpoints.down("md")]: {
        paddingRight: 16,
      },
      [theme.breakpoints.down("sm")]: {
        paddingRight: 16,
      },
    },
  },
  title: {
    paddingBottom: "1rem",
    textTransform: "uppercase",
  },
  media: {
    height: 250,
  },
  root: {
    boxShadow: "none",
  },
  cardContent: {
    padding: "16px 0",
  },
  text: {
    paddingTop: "1rem",

    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: 16,
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: 16,
    },
  },
  div: {
    marginBottom: 115,
    [theme.breakpoints.down("sm")]: {
      marginBottom: 56,
    },
  },
  link: {
    display: "block",
  },
}));

const PercheLaScuola = () => {
  const classes = useStyles();
  return (
    <div className="section-wrapper">
      <Rectangle />
      <div className={classes.block}>
        <Typography variant="h1" color="initial">
          PERCHÉ LA SCUOLA
        </Typography>
        <Divider />
        <Grid container>
          <Grid item md={12}>
            <Typography className={classes.text}>
              Il nostro paese soffre di una grave lacuna strategica. I luoghi di
              formazione della classe dirigente sono pochi e tendenti alla
              teoria più che alla pratica. Dunque gli apparati pubblici non sono
              dotati delle competenze necessarie a comprendere le forze
              impersonali che plasmano il pianeta, a penetrare i punti di vista
              degli attori geopolitici, a capire come collocare il nostro paese
              nelle competizioni mondiali. Cioè a proteggere o avanzare
              l’interesse nazionale. La nostra Scuola ha lo scopo di contribuire a ridurre questo svantaggio competitivo dell’Italia sulla scena internazionale.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PercheLaScuola;
