function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });

  return images;
}

const DocintiImages = importAll(require.context("../images/docinti", true));

export default DocintiImages;
