import React from "react";
import { Typography, makeStyles, Grid } from "@material-ui/core";
import Video from "../../Videos/HomePage.mp4";
import image from "../../images/Limes-video-cover.png";
import ReactPlayer from "react-player";
import Videos from './Videos.jsx'

const useStyles = makeStyles((theme) => ({
  image: {
    maxWidth: "100%",
    height: "auto",
  },

  blog: {
    marginTop: 32,
    textAlign: "center",
    padding: theme.spacing(6, 12.5),
    backgroundColor: "#ccd8ee",
    borderTop: "5px solid #00406c",
    borderBottom: "5px solid #00406c",
    display: "none",
    "& h4": {
      marginBottom: 8,
    },
    "& h6": {
      marginBottom: 16,
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: 60,
      paddingRight: 60,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 40,
      paddingRight: 40,
    },
  },
  auth: {
    color: "#3c3c3b",
  },
  video: {
    marginBottom: 30,
  },
  videoTitle: {
    marginBottom: 16,
  }
}));

const Block = () => {
  const classes = useStyles();
  return (
    <div className={classes.blog}>

      <Grid item md={12}>
        <Typography variant="h5" color="initial" className="video-title">
          La prima non accademia di geopolitica e di governo
        </Typography>
        <Typography
          variant="h6"
          color="initial"
          className={`auth-title ${classes.auth}`}
        >
          di Lucio Caracciolo
        </Typography>
        <Grid container className={classes.video}>
          <Grid item xs={12}>
            <div className="player-wrapper-iframe">
              <ReactPlayer
                url={`https://player.vimeo.com/video/767209429?h=4da5cd2a42&title=0&byline=0&portrait=0`}
                className="react-player"
                light={image}
                playing
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </Grid>
        </Grid>
        <Typography>
          Il direttore Lucio Caracciolo presenta la Scuola di Limes 2023
        </Typography>
      </Grid>
      <br/>
      <Grid item md={12}>
      <Typography variant="h5" color="initial" className={`video-title ${classes.videoTitle}`} >
          Il corso 2022{" "}
        </Typography>
        <Grid container className={classes.video}>
          <Grid item xs={12}>
            <div className="player-wrapper-iframe">
              <ReactPlayer
                url={`https://player.vimeo.com/video/766814888?h=2ef87aaa4a&title=0&byline=0&portrait=0`}
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </Grid>
        </Grid>
        <Typography>
        La voce dei protagonisti
        </Typography>
      </Grid>
      <br/>
      <Grid item md={12}>
      <Typography variant="h5" color="initial" className={`video-title ${classes.videoTitle}`} >
      IL LABORATORIO CARTOGRAFICO DELLA SCUOLA DI LIMES{" "}
        </Typography>
        <Grid container className={classes.video}>
          <Grid item xs={12}>
            <div className="player-wrapper-iframe">
              <ReactPlayer
                url={`https://player.vimeo.com/video/802983534?h=0ad635a695&badge=0&autopause=0&quality_selector=1&progress_bar=1&player_id=0&app_id=58479`}
                className="react-player"
                width="100%"
                height="100%"
                controls={true}
              />
            </div>
          </Grid>
        </Grid>
        <Typography>
        Teoria e tecnica della geografia geopolitica e le esercitazioni degli studenti del 2022
        </Typography>
      </Grid>
    </div >
  );
};

export default Block;
