import React, { useEffect, useRef } from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import "index.css";
import "./sass/style.scss";
import Homepage from "./pages/Home/Homepage.jsx";
import Accedi from "./pages/Accedi/Accedi.jsx";
import Icorsi from "./pages/Icorsi/Icorsi.jsx";
import Thanks from "./components/Collection/Thanks.jsx";
import Docenti from "./pages/Docenti/Docenti.jsx";
import Chisiamo from "./pages/Chisiamo/Chisiamo.jsx";
import Perleaziende from "./pages/Perleaziende.jsx/Perleaziende.jsx";
import Contatti from "./pages/Contatti/Contatti.jsx";
import ScrollToTop from "./components/Layout/ScrollToTop.jsx";
import { Container } from "@material-ui/core";
import BackToTop from "./components/Layout/BackToTop.jsx";
import privacyDocument from "./pages/Document/privacyDocument.jsx";
import Condizionidelservizio from "./components/Documents/condizionidelservizio.jsx";
import CandidaturePage from "./components/Documents/candidaturePage.jsx";
import BrochurePage from "./components/Documents/BrochurePage.jsx";
import ContattoPage from "./components/Documents/ContattoPage.jsx";

const App = () => {
  const isBottom = (el) => {
    if (el) {
      let id = el.getAttribute("id");
      if (document.querySelector(`#${id}`).classList.contains("icorsi-form")) {
        return el.getBoundingClientRect().bottom <= window.innerHeight + 600;
      } else {
        return el.getBoundingClientRect().bottom <= window.innerHeight + 100;
      }
    }
  };

  const trackScrolling = () => {
    const wrappedElement = document.getElementById("footer");
    const wrappedElementForm = document.getElementById("registration-request");
    const check = isBottom(wrappedElement);
    const checkForm = isBottom(wrappedElementForm);
    if (check) {
      if (document.getElementById("bannerButton")) {
        document.getElementById("bannerButton").classList.add("hidden");
      }
    } else if (checkForm) {
      if (document.getElementById("bannerButton")) {
        document.getElementById("bannerButton").classList.add("hidden");
      }
    } else {
      if (document.getElementById("bannerButton")) {
        document.getElementById("bannerButton").classList.remove("hidden");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", trackScrolling);
    return () => {
      window.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Container
        className="app-container wrapper-container"
        disableGutters={true}
        maxWidth={false}
      >
        <React.Fragment>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/home" component={Homepage} />
            <Route exact path="/accedi" component={Accedi} />
            <Route exact path="/icorsi" component={Icorsi} />
            <Route
              exact
              path="/grazie-registrazione"
              component={() => (
                <Thanks
                  text_top="Grazie per la vostra richiesta"
                  text_bottom="Il team di Scuola di Limes si metterà in contatto con voi."
                />
              )}
            />
            <Route
              exact
              path="/grazie-contatti"
              component={() => (
                <Thanks
                  text_top="Abbiamo ricevuto il vostro messaggio"
                  text_bottom="Il team di Scuola di Limes si metterà in contatto con voi."
                />
              )}
            />
            <Route exact path="/docenti" component={Docenti} />
            <Route exact path="/chisiamo" component={Chisiamo} />
            <Route exact path="/perleaziende" component={Perleaziende} />
            <Route exact path="/contatti" component={Contatti} />
            <Route exact path="/paginadocumenti" component={privacyDocument} />
            <Route
              exact
              path="/condizionidelservizio"
              component={Condizionidelservizio}
            />
            <Route exact path="/candidature" component={CandidaturePage} />
            <Route exact path="/contatto" component={ContattoPage} />
            <Route exact path="/brochure" component={BrochurePage} />
          </Switch>
          <BackToTop />
        </React.Fragment>
        <Footer />
      </Container>
      <ScrollToTop />
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
